import React, { FC } from 'react';
import { useMsal } from '@azure/msal-react';
import Button from '../../systemComponents/sharedControls/general/button/Button';

const UnauthorisedUserPage: FC = () => {
  const { instance } = useMsal();

  const handleLogout = () => {
    instance.logoutRedirect();
  };

  return (
    <div>
      <div>Unauthorised User</div>
      <div>
        Your email address is not associated with an account. Please contact
        your administrator if you believe this to be incorrect.
        <Button
          itemKey="logout"
          clickEvent={() => {
            handleLogout();
          }}
          mode="positive"
          label="Logout"
        />
      </div>
    </div>
  );
};

export default UnauthorisedUserPage;
