import React, { FC } from 'react';

import './BaseBox.css';
import Button from '../../general/button/Button';
import BaseBoxTypes from './BaseBox.types';
import SideBar from '../sideBar/SideBar';
import StatusBlock from '../statusBlock/StatusBlock';

const BaseBox: FC<BaseBoxTypes.Props> = (props) => {
  const renderButtons = () => {
    return !props.primaryButton ? null : (
      <div className="buttons">
        <Button
          itemKey={`primaryButton_${props.modalKey}`}
          children={props.primaryButton.children}
          clickEvent={props.primaryButton.clickEvent}
          label={props.primaryButton.label}
          mode={props.controlState}
        />
      </div>
    );
  };

  return (
    <div className="message-box" key={props.modalKey}>
      <SideBar FieldKey={props.modalKey} boxState={props.controlState} includeInnerBar={true} />
      <div className="message-box-header">
        <StatusBlock
          boxSize="small"
          key={props.modalKey}
          id={props.modalKey}
          boxState={props.controlState}
          showIcon={false}
        />
        <label className="message-box-heading Font-Heading">{props.heading}</label>
      </div>
      <div className="message-box-body">
        <StatusBlock
          boxSize="small"
          key={`${props.modalKey}_block`}
          id={props.modalKey}
          boxState={props.controlState}
          showIcon={true}
        />
        <div className="body-text">{props.children || props.bodyText}</div>
        {!props.errorString ? null : (
          <>
            <StatusBlock
              boxSize="small"
              key={`${props.modalKey}_error_block`}
              id={props.modalKey}
              boxState={props.controlState}
              showIcon={false}
            />
            <div className="lexxic-text body-text Text-Regular">{props.errorString}</div>
          </>
        )}
      </div>
      {renderButtons()}
    </div>
  );
};

export default BaseBox;
