import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import {
  fetchAction,
  createAction,
  updateAction,
} from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import PricingDefinition_Types from '../../sysObjects/apiModels/PricingDefinition.types';
import { IUserClaims } from '../../sysObjects/UserClaims.types';
import System_Types from '../../sysObjects/apiModels/System.types';

namespace pricingActions {
  /**
   * Saves the Service Definition object.
   * @param {IUserClaims} performingUser the user performing the action
   * @param {IPricingDefinition} The record to save.
   * @param {string} id The id of the record to update, if not provided, a new record will be created.
   * @returns The result of the action
   */
  export const savePricingDefinitionAsync = async (
    obj: PricingDefinition_Types.pricingDefinition,
    performingUser: IUserClaims,
    accessToken: string,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (!id) {
      return createAction({
        hostPath: host!.result!.path,
        apiPath: settings.PricingDefinitions.create.path!,
        userId: performingUser.userAccountId,
        formData: obj,
        accessToken: accessToken,
      });
    }

    return updateAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.PricingDefinitions.update.path!}/${id}`,
      userId: performingUser.userAccountId,
      formData: obj,
      accessToken: accessToken,
    });
  };

  /**
   * Saves the pricing definition item object.
   * @param {IUserClaims} performingUser the user performing the action
   * @param {IPricingDefinitionItem} The record to save.
   * @returns The result of the action
   */
  export const savePricingDefinitionItemAsync = async (
    obj: PricingDefinition_Types.pricingDefinitionItem,
    headers: FunctionalResult<System_Types.ApiHeader>
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (headers.isFailure) {
      return FunctionalResult.Error('Failed to get headers');
    }
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return createAction({
      hostPath: host!.result!.path,
      apiPath: settings.PricingDefinitions.items.create.path!,
      userId: headers.result!.userId,
      formData: obj,
      accessToken: headers.result!.token,
    });
  };

  /**
   * Finds pricing definitions asynchronously.
   *
   * @param {IUserClaims} performingUser The user performing the action.
   * @return {Promise<FunctionalResult<pricingDefinition[]>>} The result of the fetch action.
   */
  export const findActivePricingDefinitionsAsync = async (
    currency: string | null,
    performingUser: IUserClaims,
    accessToken: string
  ): Promise<FunctionalResult<PricingDefinition_Types.listItem[]>> => {
    let apiPath = `${settings.PricingDefinitions.getList.path!}?${
      settings.PricingDefinitions.getList.queryActive
    }=true`;

    if (currency) {
      apiPath = `${apiPath}&${settings.PricingDefinitions.getList.queryCurrency}=${currency}`;
    }
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: apiPath,
      userId: performingUser.userAccountId,
      accessToken: accessToken,
    });
  };

  /**
   * Gets the pricing definition.
   * @param {string} id
   * @param {IUserClaims} performingUser The user claims
   * @returns {Promise<FunctionalResult<pricingDefinition>>}  The result of the fetch action.
   */
  export const getPricingDefinitionAsync = async (
    id: string,
    performingUser: IUserClaims,
    accessToken: string
  ): Promise<
    FunctionalResult<PricingDefinition_Types.pricingDefinitionFull>
  > => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.PricingDefinitions.get.path}/${id}`,
      userId: performingUser.userAccountId,
      accessToken: accessToken,
    });
  };

  /**
   * Creates an empty record
   * @returns {pricingDefinition} - With default values
   */
  export const createDefaultPricingDefinition =
    (): PricingDefinition_Types.pricingDefinition => {
      return {
        name: '',
        description: '',
        currency: 'GBP',
        status: 1,
      };
    };

  /**
   * Creates an empty record
   * @returns {IPricingDefinitionItem} - With default values
   */
  export const createDefaultPricingDefinitionItem =
    (): PricingDefinition_Types.pricingDefinitionItem => {
      return {
        pricingDefinitionId: '',
        serviceDefinitionId: '',
        unitPrice: 0,
      };
    };
}

export default pricingActions;
