// src/pages/Dashboard/Dashboard.tsx
import React from 'react';

import { useIntl } from 'react-intl';
import { OrgActions } from '../../../../systemUtils/organisation/OrganisationActions';
import { useNavigate, useParams } from 'react-router-dom';
import Enumerations, {
  LocalEnumerations,
  getHeaders,
  getServerErrors,
} from '../../../../systemUtils/common/CommonHelpers';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import internalUserActions from '../../../../systemUtils/userUtils/InternalUserActions';
import pricingActions from '../../../../systemUtils/pricing/PricingActions';
import OrganisationTypes from '../../../../sysObjects/apiModels/Organisation.types';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import { FormControlOnChangeData, FormControlRef } from '../../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../../../sysObjects/common.types';
import CommonModalTypes from '../../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonModal from '../../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import PillControl from '../../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormTextCapture from '../../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormDropDown from '../../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import FormCheckbox from '../../../../systemComponents/sharedControls/formControls/formCheckbox/FormCheckbox';
import FormTextArea from '../../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';

const OrganisationalBillingCreateUpdate: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { id, orgId } = useParams();
  const { instance } = useMsal();

  const formRefs = [
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
      React.useRef<FormControlRef>(null),
    ],
    [React.useRef<FormControlRef>(null)],
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [formData, setFormData] =
    React.useState<OrganisationTypes.billingDetails>(
      OrgActions.createDefaultBillingDetails(orgId || '')
    );

  const [caseManagers, setCaseManagers] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [pricingDefinitions, setPricingDefinitions] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [supportedCurrencies, setSupportedCurrencies] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [taxRegimes, setTaxRegimes] = React.useState<KeyValuePair<string>[]>(
    []
  );

  const [pageStates, setPages] = React.useState<KeyValuePair<string>[]>([
    {
      key: locales.controls.pages[0],
      value: 'neutral',
    },
    {
      key: locales.controls.pages[1],
      value: 'neutral',
    },
    {
      key: locales.controls.pages[2],
      value: 'neutral',
    },
    {
      key: locales.controls.pages[3],
      value: 'neutral',
    },
  ]);

  const [errorProps, setErrorProps] = React.useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });

  const pageChange = (oldPage: number) => {
    const validationResults = formRefs[oldPage].map((ref) =>
      ref.current?.triggerValidation()
    );
    const allValid = validationResults.every((result) => result === true);

    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[oldPage] = {
        ...updatedPages[oldPage],
        value: allValid ? 'positive' : 'negative',
      };
      return updatedPages;
    });
  };

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | boolean | null
    >
  ) => {
    if (result.id.startsWith('billingAccount.')) {
      const billingField = result.id.replace('billingAccount.', '');

      if (billingField.startsWith('address.')) {
        const addressField = billingField.replace('address.', '');

        setFormData((prevData) => ({
          ...prevData,
          billingAccount: {
            ...prevData.billingAccount,
            address: {
              ...prevData.billingAccount.address,
              [addressField]: result.value,
            },
          },
        }));
      } else {
        setFormData((prevData) => ({
          ...prevData,
          billingAccount: {
            ...prevData.billingAccount,
            [billingField]: result.value,
          },
        }));
      }
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [result.fieldId]: result.value,
      }));
    }
  };

  const handleInputChangeFloat = (
    result: FormControlOnChangeData<string | number | null>
  ) => {
    const billingField = result.id.replace('billingAccount.', '');
    const val = parseFloat(result.value as string);
    setFormData((prevData) => ({
      ...prevData,
      billingAccount: {
        ...prevData.billingAccount,
        [billingField]: val,
      },
    }));
  };

  const setError = (
    message: string | React.ReactNode,
    errorString?: string,
    ent?: () => void
  ) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      errorString: errorString,
      children: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      setError(locales.errorDetails.permissionError);
      return;
    }
    if (!orgId) {
      setError(locales.errorDetails.invalidID);
      return;
    }

    internalUserActions
      .queryUsersAsync(getHeaders(userClaims, instance), {
        role: [LocalEnumerations.Roles.CaseManager],
        status: [
          LocalEnumerations.UserStatuses.Invited,
          LocalEnumerations.UserStatuses.Active,
        ],
      })
      .then((rslt) => {
        if (rslt.isFailure || rslt.result === null) {
          setError(locales.errorDetails.loadingFailed);
          return;
        }
        setCaseManagers(
          rslt.result!.map((item) => {
            return { key: item.id, value: item.name };
          })
        );
      })
      .catch(() => {
        setError(locales.errorDetails.invalidID);
        return;
      });

    setSupportedCurrencies(
      Enumerations.getSupportedCurrencies(intl.locale).map((item) => {
        return { key: item.code, value: item.description };
      })
    );

    setTaxRegimes(
      Enumerations.getSupportedTaxes(intl.locale).map((item) => {
        return { key: item.code, value: item.description };
      })
    );

    pricingActions
      .findActivePricingDefinitionsAsync(
        null,
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!
      )
      .then((rslt) => {
        if (rslt.isFailure) {
          setError(locales.errorDetails.loadingFailed);
          return;
        }
        setPricingDefinitions(
          rslt.result!.map((item) => {
            return { key: item.id, value: item.name };
          })
        );
      })
      .catch(() => {
        setError(locales.errorDetails.invalidID);
        return;
      });
    setIsLoading(false);

    if (!id) {
      setIsLoading(false);
      return;
    }
    OrgActions.getBillingById(
      id,
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!
    )
      .then((result) => {
        if (result.isFailure) {
          setError(locales.errorDetails.invalidID);
          return;
        }

        setFormData(result.result!);
      })
      .catch(() => {
        setError(locales.errorDetails.invalidID);
        return;
      });
  }, []);

  const save = () => {
    let allValid: boolean[] = [];

    formRefs.forEach((ref, i) => {
      const validationResults = ref.map((innerRef) =>
        innerRef.current?.triggerValidation()
      );
      const tabValid = validationResults.every((result) => result === true);
      allValid.push(tabValid);
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[i] = {
          ...updatedPages[i],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
    });

    const pageValid = allValid.every((isValid) => isValid);
    if (!pageValid) {
      return;
    }

    OrgActions.saveBillingDetailsAsync(
      formData,
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!,
      id
    )
      .then((result) => {
        if (result.isFailure) {
          setError(
            getServerErrors(locales, result.errorCode),
            result.error,
            () => {
              setErrorProps((prevData) => ({
                ...prevData,
                show: false,
              }));
            }
          );
          return;
        }
        navigate('/Organisations');
      })
      .catch(() => {
        setError(locales.errorDetails.saveFailed, '', () => {
          setErrorProps((prevData) => ({
            ...prevData,
            show: false,
          }));
        });
      });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : errorProps.show ? (
    <div>
      <CommonModal
        headerText={errorProps.headerText}
        buttonDetails={errorProps.buttonDetails}
        show={errorProps.show}
      >
        {errorProps.children || errorProps.bodyText}
      </CommonModal>
    </div>
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        backLabel={locales.controls.back}
        nextLabel={locales.controls.next}
        pageChangeAction={pageChange}
        pages={[
          {
            mode: pageStates[0].value as ControlState,
            name: pageStates[0].key,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="assignedRelationshipManager"
                  id="assignedRelationshipManager"
                  label={locales.controls.assignedRelationshipManager}
                  value={formData.assignedRelationshipManager}
                  onChange={handleFormChange}
                  ref={formRefs[0][0]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="assignedConsultingBusinessPsychologist"
                  id="assignedConsultingBusinessPsychologist"
                  label={
                    locales.controls.assignedConsultingBusinessPsychologist
                  }
                  value={formData.assignedConsultingBusinessPsychologist}
                  onChange={handleFormChange}
                  ref={formRefs[0][1]}
                  textInputType="text"
                />
                <FormDropDown
                  displayMode="Box"
                  id="assignedCaseManager"
                  fieldId="assignedCaseManager"
                  items={caseManagers}
                  label={locales.controls.assignedCaseManager}
                  defaultText={locales.common.Select_Default}
                  value={formData.assignedCaseManager}
                  onChange={handleFormChange}
                  ref={formRefs[0][2]}
                  multiple
                  size={4}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.assignedCaseManager} ${locales.common.requiredMessage}`,
                  }}
                />
                <FormCheckbox
                  displayMode="Box"
                  id="isPORequired"
                  fieldId="isPORequired"
                  label={locales.controls.isPORequired}
                  value={formData.isPORequired}
                  onChange={handleFormChange}
                  ref={formRefs[0][3]}
                />
              </>
            ),
          },
          {
            mode: pageStates[1].value as ControlState,
            name: pageStates[1].key,
            enabled: true,
            orderNo: 2,
            showAsOrdered: true,
            content: (
              <>
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.fullName"
                  id="billingAccount.fullName"
                  label={locales.controls.fullName}
                  value={formData.billingAccount.fullName}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.fullName} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][0]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.emailAddress"
                  id="billingAccount.emailAddress"
                  label={locales.controls.emailAddress}
                  value={formData.billingAccount.emailAddress}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.emailAddress} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][1]}
                  textInputType="email"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.line1"
                  id="billingAccount.address.line1"
                  label={locales.controls.line1}
                  value={formData.billingAccount.address.line1}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.line1} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][2]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.line2"
                  id="billingAccount.address.line2"
                  label={locales.controls.line2}
                  value={formData.billingAccount.address.line2}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.line2} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][3]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.line3"
                  id="billingAccount.address.line3"
                  label={locales.controls.line3}
                  value={formData.billingAccount.address.line3}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.line3} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][4]}
                  textInputType="text"
                />{' '}
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.postalCode"
                  id="billingAccount.address.postalCode"
                  label={locales.controls.postalCode}
                  value={formData.billingAccount.address.postalCode}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.postalCode} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][5]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.address.country"
                  id="billingAccount.address.country"
                  label={locales.controls.country}
                  value={formData.billingAccount.address.country}
                  maxLength={250}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.country} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][6]}
                  textInputType="text"
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="billingAccount.telephoneNumber"
                  id="billingAccount.telephoneNumber"
                  label={locales.controls.telephoneNumber}
                  value={formData.billingAccount.telephoneNumber}
                  onChange={handleFormChange}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.telephoneNumber} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][7]}
                  textInputType="tel"
                />
              </>
            ),
          },
          {
            mode: pageStates[2].value as ControlState,
            name: pageStates[2].key,
            enabled: true,
            orderNo: 3,
            showAsOrdered: true,
            content: (
              <>
                <FormDropDown
                  displayMode="Box"
                  id="pricingDefinitionId"
                  fieldId="pricingDefinitionId"
                  items={pricingDefinitions}
                  label={locales.controls.pricingDefinitionId}
                  defaultText={locales.common.Select_Default}
                  value={formData.pricingDefinitionId}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.pricingDefinitionId} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  ref={formRefs[2][0]}
                />
                <FormDropDown
                  displayMode="Box"
                  id="billingAccount.currency"
                  fieldId="billingAccount.currency"
                  items={supportedCurrencies}
                  label={locales.controls.currency}
                  defaultText={locales.common.Select_Default}
                  value={formData.billingAccount.currency}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.currency} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  ref={formRefs[2][1]}
                />
                <FormDropDown
                  displayMode="Box"
                  id="billingAccount.taxCode"
                  fieldId="billingAccount.taxCode"
                  items={taxRegimes}
                  label={locales.controls.taxCode}
                  defaultText={locales.common.Select_Default}
                  value={formData.billingAccount.taxCode}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.taxCode} ${locales.common.requiredMessage}`,
                  }}
                  onChange={handleFormChange}
                  ref={formRefs[2][2]}
                />
              </>
            ),
          },
          {
            mode: pageStates[3].value as ControlState,
            name: pageStates[3].key,
            enabled: true,
            orderNo: 4,
            showAsOrdered: true,
            content: (
              <FormTextArea
                displayMode="Box"
                id="notes"
                fieldId="notes"
                label={locales.controls.notes}
                value={formData.notes}
                onChange={handleFormChange}
                ref={formRefs[3][0]}
              />
            ),
          },
        ]}
      />
      <InformationButton
        key="informationSave"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: () => save(),
          label: locales.controls.saveButton,
          mode: 'positive',
        }}
      >
        <>{locales.controls.summary}</>
      </InformationButton>
    </div>
  );
};

export default OrganisationalBillingCreateUpdate;
