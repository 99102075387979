import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import pricingTypes from '../../../sysObjects/apiModels/PricingDefinition.types';
import actions from '../../../systemUtils/pricing/PricingActions';
import Enumerations, {
  getHeaders,
  getServerErrors,
  LocalEnumerations,
} from '../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import ServiceDefinitionActions from '../../../systemUtils/services/ServiceDefinitionActions';
import { PricingDefinitionItemTypes } from '../../../systemComponents/targetedPageControls/pricing/pricingItemRow/PricingItemRow.types';
import pricingActions from '../../../systemUtils/pricing/PricingActions';
import PricingItemList from '../../../systemComponents/targetedPageControls/pricing/pricingItemList/PricingItemList';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import ServiceDefinition_Types from '../../../sysObjects/apiModels/ServiceDefinition.types';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormTextArea from '../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import { PillControlPageProps } from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';

const PricingDefinition_CU: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const { id } = useParams();
  const { instance } = useMsal();
  const context = React.useContext(CrumbUpdateContext);
  const [refreshRequired, setRefreshRequired] = React.useState<boolean>(true);

  const formRefs = [
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
    React.useRef<FormControlRef>(null),
  ];

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [supportedCurrencies, setSupportedCurrencies] = React.useState<
    KeyValuePair<string>[]
  >([]);
  const [tabStatus, setTabStatus] = React.useState<ControlState>('neutral');
  const [serviceDefinitions, setServiceDefinitions] = React.useState<
    ServiceDefinition_Types.RetrievedServiceDefinition[]
  >([]);
  const [deliveryFormat, setDeliveryFormats] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [relatedConditions, setRelatedConditions] = React.useState<
    KeyValuePair<number>[]
  >([]);
  const [rows, setRows] = React.useState<PricingDefinitionItemTypes.Item[]>();

  const [errorProps, setErrorProps] = React.useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const setError = (
    message: string | React.ReactNode,
    errorString?: string,
    ent?: () => void,
  ) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      errorString: errorString,
      children: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  const [formData, setFormData] =
    React.useState<pricingTypes.pricingDefinition>(
      actions.createDefaultPricingDefinition(),
    );

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      setError(locales.errorDetails.permissionError);
      return;
    }

    setSupportedCurrencies(
      Enumerations.getSupportedCurrencies(intl.locale).map((currency) => {
        return { key: currency.code, value: currency.description };
      }),
    );
    context?.onEvent(
      [...locales.breadcrumbs].slice(0, locales.breadcrumbs.length - 1),
    );

    if (id) {
      actions
        .getPricingDefinitionAsync(
          id,
          userClaims!.user!,
          instance.getActiveAccount()!.idToken!,
        )
        .then((result) => {
          if (result.isFailure) {
            setError(locales.errorDetails.loadingFailed!);
            return;
          }
          setFormData({
            currency: result.result!.currency,
            description: result.result!.description,
            name: result.result!.name,
            status: result.result!.status,
          });
          context?.onEvent(
            [...locales.breadcrumbs]
              .slice(0, locales.breadcrumbs.length - 1)
              .concat({
                label: result.result!.name,
                key: id,
              }),
          );
          setIsLoading(false);
        })
        .catch(() => {
          setError(locales.errorDetails.loadingFailed!);
        });
    } else {
      setIsLoading(false);
      context?.onEvent([...locales.breadcrumbs]);
    }
  }, []);

  React.useEffect(() => {
    if (!id) {
      return;
    }
    if (!refreshRequired) {
      return;
    }

    setRefreshRequired(false);
    context?.onEvent(locales.breadcrumbs);

    preparePriceListTab(id);
  }, [refreshRequired]);

  const determineRelatedCondition = (
    retrievedRelatedConditions: KeyValuePair<number>[],
    item: ServiceDefinition_Types.RetrievedServiceDefinition,
  ) => {
    const relatedText = retrievedRelatedConditions.find(
      (x) => x.key === item.relatedCondition!,
    )!.value;

    return item.relatedCondition !== 99
      ? relatedText
      : `${retrievedRelatedConditions.find((x) => x.key === item.relatedCondition!)!.value} - ${item.otherRelatedCondition}`;
  };

  const preparePriceListTab = async (id: string) => {
    const retrievedDeliveryFormats = Enumerations.getDeliveryFormats(
      intl.locale,
    );
    const retrievedRelatedConditions = Enumerations.getRelatedConditions(
      intl.locale,
    );
    setDeliveryFormats(retrievedDeliveryFormats);
    setRelatedConditions(retrievedRelatedConditions);

    if (!id || !relatedConditions || !deliveryFormat) {
      setError(locales.errorDetails.loadingFailed);
      return;
    }

    const pricingDefinitionsResult =
      await pricingActions.getPricingDefinitionAsync(
        id,
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!,
      );

    if (pricingDefinitionsResult.isFailure) {
      setError(locales.errorDetails.loadingFailed);
      return;
    }

    const serviceDefinitionResult = await ServiceDefinitionActions.findAsync(
      {
        active: LocalEnumerations.ServiceDefinitionStatuses.Active,
      },
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!,
    );

    if (serviceDefinitionResult.isFailure) {
      setError(locales.errorDetails.loadingFailed);
      return;
    }

    setServiceDefinitions(serviceDefinitionResult.result!);

    setRows(
      pricingDefinitionsResult!
        .result!.pricingDefinitionItems!.filter(
          (x) => x.validTo === undefined || x.validTo === null,
        )
        .sort(
          (a, b) =>
            new Date(a.validFrom).getTime() - new Date(b.validFrom).getTime(),
        )
        .map((item) => {
          return {
            id: item.id,
            currency: pricingDefinitionsResult.result!.currency,
            name: item.serviceDefinition.serviceType,
            price: item.unitPrice,
            code: item.serviceDefinition.serviceCode,
            relatedCondition: determineRelatedCondition(
              retrievedRelatedConditions,
              item.serviceDefinition,
            ),
            deliveryFormat: retrievedDeliveryFormats.find(
              (x) => x.key === item.serviceDefinition.deliveryFormat!,
            )!.value,
            validFrom: item.validFrom,
            selectedServiceId: item.serviceDefinitionId,
            onSave: (price, serviceId) => {
              pricingActions
                .savePricingDefinitionItemAsync(
                  {
                    pricingDefinitionId: id,
                    serviceDefinitionId: serviceId,
                    unitPrice: price,
                  },
                  getHeaders(userClaims, instance),
                )
                .then((result) => {
                  if (result.isFailure) {
                    setError(locales.errorDetails.saveFailed);
                    return;
                  }

                  setRefreshRequired(true);
                });
            },
          };
        }),
    );
  };

  const appendRemainingServices = (
    presentItems: PricingDefinitionItemTypes.Item[],
  ) => {
    const serviceIds = presentItems.map((item) => item.selectedServiceId);
    const remainingServices = serviceDefinitions.filter(
      (service) => !serviceIds.includes(service.id),
    );

    let remainingItems: PricingDefinitionItemTypes.Item[] =
      remainingServices.map((service) => {
        return {
          currency: formData.currency,
          name: service.serviceType,
          price: 0,
          code: service.serviceCode,
          relatedCondition: determineRelatedCondition(
            relatedConditions,
            service,
          ),
          deliveryFormat: deliveryFormat.find(
            (x) => x.key === service.deliveryFormat!,
          )!.value,
          selectedServiceId: service.id,
          onSave: (price, serviceId) => {
            pricingActions
              .savePricingDefinitionItemAsync(
                {
                  pricingDefinitionId: id!,
                  serviceDefinitionId: serviceId,
                  unitPrice: price,
                },
                getHeaders(userClaims, instance),
              )
              .then((result) => {
                if (result.isFailure) {
                  setError(locales.errorDetails.saveFailed);
                  return;
                }

                setRefreshRequired(true);
              });
          },
        };
      });

    return [...presentItems, ...remainingItems];
  };

  const saveRecord = () => {
    const validationResults = formRefs.map((innerRef) =>
      innerRef.current?.triggerValidation(),
    );
    const tabValid = validationResults.every((result) => result === true);

    if (!tabValid) {
      setTabStatus('negative');
      return;
    }

    actions
      .savePricingDefinitionAsync(
        formData,
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!,
        id,
      )
      .then((result) => {
        if (result.isFailure) {
          setError(
            getServerErrors(locales, result.errorCode),
            result.error,
            () => {
              setErrorProps((prevData) => ({
                ...prevData,
                show: false,
              }));
            },
          );
          return;
        }
        navigate('/pricing');
      });
  };

  const renderPriceItems = () => {
    let rowsToDisplay = rows || [];
    if (!id) {
      return null;
    }

    rowsToDisplay = appendRemainingServices(rowsToDisplay);

    return (
      <PricingItemList
        rows={rowsToDisplay}
        labels={{
          ...locales.pricingList,
          table: {
            ...locales.pricingList.table,
            tableName: `${locales.pricingList.table.tableName} - ${formData.name}`,
          },
        }}
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        triggerRefresh={() => {
          setRefreshRequired(true);
        }}
      />
    );
  };

  const determineTabs = () => {
    let list = [
      {
        name: locales.tabs.details,
        mode: tabStatus,
        enabled: true,
        orderNo: 1,
        showAsOrdered: true,
        content: (
          <>
            <FormTextCapture
              id="name"
              fieldId="name"
              label={locales.name}
              textInputType="text"
              value={formData.name}
              maxLength={250}
              requiredDetails={{
                formLabel: locales.common.required,
                message: `${locales.name} ${locales.common.requiredMessage}`,
              }}
              ref={formRefs[0]}
              onChange={handleFormChange}
              displayMode={'Box'}
            />
            <FormTextArea
              fieldId="description"
              id="description"
              label={locales.description}
              value={formData.description}
              maxLength={250}
              ref={formRefs[1]}
              onChange={handleFormChange}
              displayMode={'Box'}
            />
            <FormDropDown
              fieldId="currency"
              id="currency"
              value={formData.currency}
              items={supportedCurrencies}
              label={locales.currency}
              defaultText={locales.defaults.currency}
              onChange={handleFormChange}
              requiredDetails={{
                formLabel: locales.common.required,
                message: `${locales.currency} ${locales.common.requiredMessage}`,
              }}
              ref={formRefs[2]}
              displayMode={'Box'}
            />
          </>
        ),
      },
    ] as PillControlPageProps[];

    if (id) {
      list.push({
        name: locales.tabs.pricing,
        mode: 'neutral',
        content: renderPriceItems(),
        enabled: true,
        orderNo: 2,
        showAsOrdered: true,
      });
    }

    return list;
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : errorProps.show ? (
    <div>
      <CommonModal
        headerText={errorProps.headerText}
        buttonDetails={errorProps.buttonDetails}
        show={errorProps.show}
      >
        {errorProps.children || errorProps.bodyText}
      </CommonModal>
    </div>
  ) : (
    <div className="Main-Form-Layout">
      <PillControl key={'pricingDefinition'} pages={determineTabs()} />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButton',
            label: locales.infoButton,
            mode: 'positive',
            clickEvent: saveRecord,
          }}
        >
          {locales.infoSummary}
        </InformationButton>
      </div>
    </div>
  );
};

export default PricingDefinition_CU;
