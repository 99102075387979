import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/services/ServiceDefinitionActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import CommonTableTypes from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable.types';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';

const ServiceDefinition_index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CrumbUpdateContext);
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorProps, setErrorProps] = React.useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });
  const [pricings, setPricings] = React.useState<CommonTableTypes.DataRow[]>(
    []
  );
  const { instance } = useMsal();

  const setErrorMessages = (message: string, ent?: () => void) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      bodyText: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    actions
      .findAsync(
        { active: 0 },
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!
      )
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          setErrorMessages(locales.errorDetails.loadingFailed);
          return;
        }

        context?.onEvent(locales.breadcrumbs);

        setPricings(
          result.result.map((service, index) => {
            return {
              id: service.id,
              data: [
                service.serviceCode,
                service.serviceType,
                service.nominalCode,
                service.description,
              ],
              buttonColumn: (
                <ButtonBox
                  id={`ButtonBox-${index}-${service.id}`}
                  displayBorder={false}
                  key={`ButtonBox-${index}-${service.id}`}
                  buttons={[
                    {
                      controlState: 'positive',
                      label: locales.editButton,
                      id: `edit-${service.id}`,
                      onClick: () => navigate(`/services/edit/${service.id}`),
                    },
                    service.assessmentAppointmentDefinition !== null
                      ? {
                          id: `editAssessmentDefinition-${service.id}`,
                          controlState: 'positive',
                          label: locales.editAssessmentDefinitionButton,
                          onClick: () =>
                            navigate(
                              `/services/${
                                service.id
                              }/appointmentTemplate/edit/${
                                service.assessmentAppointmentDefinition!.id
                              }`
                            ),
                        }
                      : {
                          id: `createAssessmentDefinition-${service.id}`,
                          controlState: 'positive',
                          label: locales.createAssessmentDefinitionButton,
                          onClick: () =>
                            navigate(
                              `/services/${service.id}/appointmentTemplate/create`
                            ),
                        }
                  ]}
                />
              ),
            };
          })
        );
      });
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : !errorProps.show ? (
    <div className="Main-Form-Layout">
      <CommonTable
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={pricings}
        labels={{
          tableName: locales.tableHeading,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () => navigate('/services/create'),
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  ) : (
    <CommonModal
      headerText={errorProps.headerText}
      bodyText={errorProps.bodyText}
      buttonDetails={errorProps.buttonDetails}
      show={errorProps.show}
    />
  );
};

export default ServiceDefinition_index;
