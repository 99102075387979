import React, { useState, useContext, } from 'react';
import { LuChevronRight, LuChevronDown } from 'react-icons/lu';
import {
  AddServiceRowProps,
} from './AddServiceRow.types';
import { useIntl } from 'react-intl';
import { getServerErrors } from '../../../../../systemUtils/common/CommonHelpers';
import { CaseUtils } from '../../../../../systemUtils/case/caseUtils';
import { useMsal } from '@azure/msal-react';
import './AddServiceRow.css';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import FormSearchableDropDownTypes from '../../../../sharedControls/formControls/formSearchableDropDown/FormSearchableDropDown.types';
import FormSearchableDropDown from '../../../../sharedControls/formControls/formSearchableDropDown/FormSearchableDropDown';
import Button from '../../../../sharedControls/general/button/Button';
import StatusLabel from '../../../../sharedControls/reusableBlocks/statusLabel/StatusLabel';
import Expander from '../../../../sharedControls/general/expander/Expander';


const AddServiceRow: React.FC<AddServiceRowProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [selecting, setSelecting] = useState<boolean>(true);
  const [selectedService, setSelectedService] = useState<string>();
  const [expandedService, setExpandedService] = useState<string>();
  const [poNumber, setPoNumber] = useState<string>();

  const intl = useIntl();
  const messages = require(`./locales/${intl.locale}.json`);
  const { instance } = useMsal();
  const { userClaims } = useContext(UserClaimsContext);

  const handleServiceSelection = () => {
    if (!selectedService || selectedService === '') {
      return;
    }

    setExpandedService(selectedService);
    setSelecting(false);
  };

  const handleSave = () => {
    if (!expandedService || expandedService === '') {
      return;
    }

    CaseUtils.addServiceToFulfillToCaseAsync(
      {
        serviceId: expandedService!,
        caseId: props.caseId,
        poNumber: poNumber,
      },
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!
    )
      .then((result) => {
        if (result.isFailure) {
          setError(getServerErrors(messages, result.errorCode));
          return;
        }

        handleCancellation();

        if (props.events.onServiceAdded) {
          props.events.onServiceAdded();
        }
      })
      .catch(() => {
        setError(messages.errorDetails.saveFailed);
      });
  };

  const handleCancellation = () => {
    setSelectedService('');
    setExpandedService('');
    setPoNumber('');
    setSelecting(true);
  };

  const setError = (message: string | React.ReactNode, ent?: () => void) => {
    props.events?.onError(message);
  };

  const setSelectedServiceValue = (e: string | number | string[] | null) => {
    if (typeof e === 'string') {
      setSelectedService(e);
    }
  };

  const renderItems = () => {
    if (
      !props.serviceDefinitionItems ||
      props.serviceDefinitionItems.length === 0
    ) {
      return [];
    }
    return props.serviceDefinitionItems.map((item) => {
      return {
        key: item.id,
        value:
          props.serviceDefinitions.find((x) => x.key === item.id)?.value ||
          item.id,
        children: (
          <>
            <span className="neutral">Price</span>
            <div>
              {
                props.supportedCurrencies?.find(
                  (x) => x.code === item.organisationPrice.currency
                )?.symbol
              }
              {item.organisationPrice.unitPrice}
            </div>
          </>
        ),
      } as FormSearchableDropDownTypes.SearchableItem;
    });
  };

  const renderExpanded = () => {
    return (
      <div className="Expanded">
        {selecting ? renderSelect() : renderSummary()}
      </div>
    );
  };

  const renderSelect = () => {
    return (
      <>
        <div className="col-Service">
          <FormSearchableDropDown
            items={renderItems()}
            fieldId={'selectedServiceDefinition'}
            id={'serviceDefinitionId'}
            label={messages.service}
            requiredDetails={{ formLabel: '', message: '' }}
            defaultText={messages.controls.service.defaultText}
            onChange={(e) => {
              setSelectedServiceValue(e.value);
            }}
            displayMode='Column'
            value={selectedService || ''}
          />
          <div className="col">
            <Button
              itemKey={'colButton'}
              label="Select"
              mode="positive"
              clickEvent={() => handleServiceSelection()}
              disabled={selectedService === undefined || selectedService === ''}
            />
          </div>
        </div>
      </>
    );
  };

  const renderSummary = () => {
    let service = props.serviceDefinitionItems?.find(
      (x) => x.id === selectedService
    );
    let currencySymbol = props.supportedCurrencies?.find(
      (x) => x.code === service?.organisationPrice.currency
    )?.symbol;

    return (
      <>
        <div className="col Text-Regular">{messages.controls.serviceType}</div>
        <div className="col Text-Regular Value-Column"> {service?.serviceType} </div>
        <div className="col Text-Regular">{messages.controls.price}</div>
        <div className="col Text-Regular Value-Column">
          {`${currencySymbol}
        ${service?.organisationPrice.unitPrice}`}
        </div>
        {
          // Todo: uncomment when PO number added to the post item in LX-456
          /*<div className="col Text-Regular"> {messages.controls.poNumber}</div>
        <div className="col">
          <FormTextCapture
            fieldId="poNumber"
            id="poNumber"
            label={''}
            textInputType="text"
            value={poNumber}
            displayMode='Column'
            maxLength={250}
            onChange={(e) => setPoNumber(e.value as string)}
          />
        </div>
  */
        }
        <div className="col Text-Regular">Actions</div>
        <div className="Button-Block">
          <Button
            itemKey={'SaveButton'}
            label={messages.controls.saveButton}
            data-testid="saveButton"
            clickEvent={() => handleSave()}
            mode="positive"
          />
          <Button
            itemKey={'CancelButton'}
            data-testid="cancelButton"
            label={messages.controls.cancelButton}
            clickEvent={() => handleCancellation()}
            mode="negative"
          />
        </div>

        <div className="col Text-Regular">
          {messages.controls.deliveryMethod}
        </div>
        <div className="col Value-Column">
          <StatusLabel
            label={
              props.deliveryFormats.find(
                (x) => x.key === service?.deliveryFormat
              )?.value || ''
            }
            renderAs="span"
            key={`service_row_status_${service?.id}`}
            status="neutral"
          />
        </div>

        <div className="col Text-Regular">
          {messages.controls.relatedCondition}
        </div>
        <div className="col Text-Regular Value-Column">
          {
            props.relatedConditions.find(
              (x) => x.key === service?.relatedCondition
            )?.value
          }
        </div>

        {service?.otherRelatedCondition && (
          <>
            <div className="col Text-Regular">   {messages.controls.otherConditions}</div>
            <div className="col Text-Regular Value-Column">{service?.otherRelatedCondition} </div>
          </>
        )}

        <div className="col Text-Regular">{messages.controls.serviceCode}</div>
        <div className="col Text-Regular Value-Column">{service?.serviceCode}</div>

        <div className="col Text-Regular">{messages.controls.nominalCode}</div>
        <div className="col Text-Regular Value-Column">{service?.nominalCode}</div>

        <div className="col Text-Regular">{messages.controls.description}</div>
        <div className="col Text-Regular Value-Column">{service?.description}</div>
      </>
    );
  };

  return (
    <div className="Service-Add-Row">
      <Expander 
        key={`Add_Status`}
        id={`Add_Status`}
        isExpanded={expanded}
        eventHandler={{
          onClick: () => {
            setExpanded(!expanded);
            if (!expanded) {
              setSelecting(true);
            }
          },
        }}
      />
      <div className="Service-Add-Row-Title Text-Strong col">{messages.addService}</div>
      {expanded && renderExpanded()}
    </div>
  );
};

export default AddServiceRow;
