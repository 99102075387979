import ServiceDefinition_Types from '../../../../../sysObjects/apiModels/ServiceDefinition.types';
import Organisation_Types from '../../../../../sysObjects/apiModels/Organisation.types';
import { KeyValuePair } from '../../../../../sysObjects/common.types';

export interface AddServiceRowProps {
  /** A key value pair mapping for possible service definitions. */
  serviceDefinitions: KeyValuePair<string>[];

  /** A key value pair mapping for possible related conditions. */
  relatedConditions: KeyValuePair<number>[];

  /** A key value pair mapping for possible delivery formats. */
  deliveryFormats: KeyValuePair<number>[];

  /** The possible service definition items. */
  serviceDefinitionItems?: ServiceDefinition_Types.serviceDefinitionItemWithPrice[];

  /** The possible currencies. */
  supportedCurrencies: Organisation_Types.supportedCurrency[];

  /** The display mode of the component. */
  serviceDisplayMode?: AddServiceDisplayMode;

  /** The case identifier. */
  caseId: string;

  events: {
    /** The Error event */
    onError: (message: string | React.ReactNode) => void;
    /** The function to call when a service is added. */
    onServiceAdded?: () => void;
  };
}

/** The display modes of the control */
export enum AddServiceDisplayMode {
  Row = 'row',
  Select = 'select',
  Summary = 'summary',
}
