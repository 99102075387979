import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Case_Types,
  CaseDetail_Types,
} from '../../../sysObjects/apiModels/Case.types';
import CaseUtils from '../../../systemUtils/case/caseUtils';
import { useIntl } from 'react-intl';
import CaseServiceTab from '../../../systemComponents/targetedPageControls/case/tabs/caseServicesTab/CaseServicesTab';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import Enumerations, {
  getHeaders,
  getCurrentStatus,
  LocalEnumerations,
  getTimeSince,
} from '../../../systemUtils/common/CommonHelpers';
import AppointmentsTab from '../../../systemComponents/targetedPageControls/case/tabs/appointmentsTab/AppointmentsTab';
import BillableItemsTab from '../../../systemComponents/targetedPageControls/case/tabs/billableItemsTab/BillableItemsTab';
import StatusHistory from '../../../sysObjects/apiModels/StatusHistory.types';
import BookingRowTypes from '../../../systemComponents/targetedPageControls/case/appointments/bookingRow/BookingRow.types';
import ServiceDefinitionActions from '../../../systemUtils/services/ServiceDefinitionActions';
import DocumentTab from '../../../systemComponents/targetedPageControls/case/tabs/documentsTab/DocumentsTab';
import NotesTab from '../../../systemComponents/targetedPageControls/case/tabs/noteTab/NotesTab';
import DocumentUtils from '../../../systemUtils/document/DocumentActions';
import DocumentActions from '../../../systemUtils/document/DocumentActions';
import DocumentRowTypes from '../../../systemComponents/targetedPageControls/case/documentDetails/documentRow/DocumentRow.types';
import CaseNote_Types from '../../../sysObjects/apiModels/CaseNote.types';
import DateHelpers from '../../../systemUtils/common/DateHelpers';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import ServicesToFulfillActions from '../../../systemUtils/services/ServicesToFulfillActions';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import BillableItemActions from '../../../utils/BillableItemsUtils/BillableItemsActions';
import InternalUser_Types from '../../../sysObjects/apiModels/InternalUser.types';
import { ServicesToFulfill_Types } from '../../../sysObjects/apiModels/ServicesToFulfill.types';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';

const CaseContainerPage: React.FC = () => {
  const intl = useIntl();
  const { userClaims } = useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();

  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const context = React.useContext(CrumbUpdateContext);

  const { id } = useParams();
  const [caseDetails, setCaseDetails] =
    React.useState<Case_Types.RetrievedCase | null>(null);

  const [caseDocuments, setCaseDocuments] = useState<
    DocumentRowTypes.Document[]
  >([]);
  const [caseNotes, setCaseNotes] = useState<CaseNote_Types.RetrievedNote[]>(
    [],
  );

  const [refreshRequired, setRefreshRequired] = useState<boolean>(true);
  const [documentRefreshRequired, setDocumentRefreshRequired] =
    useState<boolean>(true);
  const [noteRefreshRequired, setNoteRefreshRequired] = useState<boolean>(true);

  const [pageNumber, setPageNumber] = useState<number>(1);

  const [loading, setLoading] = useState<boolean>(true);
  const [documentFlicker, setDocumentFlicker] = useState<boolean>(false);
  const [errorProps, setErrorProps] = useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });

  const formats = Enumerations.getDeliveryFormats(intl.locale);
  const conditions = Enumerations.getRelatedConditions(intl.locale);
  const caseStatuses = Enumerations.getCaseStatuses(intl.locale);

  const canUpdateStatus = (
    history: StatusHistory<number>[],
  ): { canUpdateStatus: Boolean; stateId: Number } => {
    const canEdit = UserCore.userIsAssociateAssessorOrHigher(userClaims?.user!);
    if (canEdit !== true) {
      return { canUpdateStatus: false, stateId: 0 };
    }

    if (history === undefined || history === null || history.length === 0) {
      return { canUpdateStatus: false, stateId: 0 };
    }
    history.sort((a, b) => {
      const dateA = new Date(a.createdDateTime);
      const dateB = new Date(b.createdDateTime);
      return (dateB as any) - (dateA as any);
    });

    const value = history[0].value;
    return {
      canUpdateStatus:
        value === LocalEnumerations.AppointmentStatuses.Offered ||
        value === LocalEnumerations.AppointmentStatuses.Confirmed,
      stateId: value,
    };
  };

  const isAppointmentService = (history: StatusHistory<number>[]): boolean => {
    if (history === undefined || history === null || history.length === 0) {
      return false;
    }

    return history.some(
      (historyItem) =>
        historyItem.value ===
        LocalEnumerations.ServiceStatuses.RequiresAppointment,
    );
  };

  const canAddBillableItems = () => {
    if (UserCore.userIsCaseManagerOrHigher(userClaims?.user!) === false) {
      return false;
    }

    if (
      caseDetails?.servicesToFulfill === undefined ||
      caseDetails?.servicesToFulfill === null ||
      caseDetails?.servicesToFulfill.length === 0
    ) {
      return false;
    }

    let caseStatus = getCurrentStatus(caseDetails?.statusHistory);

    if (
      caseStatus.value !==
      LocalEnumerations.CaseStatuses.ActiveServiceDeliveries
    ) {
      return false;
    }

    return true;
  };

  const needsAppointmentBooking = (
    history: StatusHistory<number>[],
  ): boolean => {
    if (history === undefined || history === null || history.length === 0) {
      return false;
    }
    history.sort((a, b) => {
      const dateA = new Date(a.createdDateTime);
      const dateB = new Date(b.createdDateTime);
      return (dateB as any) - (dateA as any);
    });

    const value = history[0].value;
    return value === LocalEnumerations.ServiceStatuses.RequiresAppointment;
  };

  const getAppointments = (
    item: CaseDetail_Types.RetrievedServiceToFulfill,
  ): BookingRowTypes.ServiceDetails[] => {
    var result = item.appointments.map((appointment, idx) => {
      const stateDetails = canUpdateStatus(appointment.statusHistory);
      return {
        isExpanded: false,
        rowId: `${item.id}_${idx}`,
        serviceToFulfillId: item.id,
        serviceDefinitionId: item.serviceId,
        serviceName:
          ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
            item.serviceDefinition!,
            formats,
            conditions,
          ).value,
        duration:
          item.serviceDefinition?.assessmentAppointmentDefinition?.duration,
        bookingDetails: {
          appointmentId: appointment.id,
          statusId: stateDetails.stateId as number,
          bookingDate: appointment.startDateTime,
          duration: appointment.duration,
          canEditBooking: false,
          isChargeable: appointment.isChargeable,
          state: stateDetails,
          isInvoiced: isAppointmentInvoiced(item),
          name: `${
            appointment.assessor.surname
              ? appointment.assessor.surname + ', '
              : ''
          }${
            appointment.assessor.name ? appointment.assessor.name : ''
          }`.trim(),
        } as BookingRowTypes.BookingDetails,
      };
    });

    if (
      needsAppointmentBooking(item.statusHistory) &&
      !result.some((item) =>
        [
          LocalEnumerations.AppointmentStatuses.Offered,
          LocalEnumerations.AppointmentStatuses.Confirmed,
        ].includes(item.bookingDetails!.statusId as number),
      )
    ) {
      result.push({
        isExpanded: false,
        rowId: item.id,
        serviceToFulfillId: item.id,
        serviceDefinitionId: item.serviceDefinition?.id,
        duration:
          item.serviceDefinition?.assessmentAppointmentDefinition?.duration,
        serviceName:
          ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
            item.serviceDefinition!,
            formats,
            conditions,
          ).value,
      } as any);
    }

    return result as BookingRowTypes.ServiceDetails[];
  };

  const isAppointmentInvoiced = (serviceToFulfill: CaseDetail_Types.RetrievedServiceToFulfill) => {
    if(serviceToFulfill.billableItems === undefined || serviceToFulfill.billableItems === null || serviceToFulfill.billableItems.length === 0) {
      return false;
    }

    return serviceToFulfill!.billableItems.some((item) => item.isInvoiced);
  };

  const setErrorMessages = (
    message: string | React.ReactNode,
    ent?: () => void,
  ) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      bodyText: typeof message === 'string' ? message : undefined,
      children: typeof message !== 'string' ? message : undefined,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  const [pageStates, setPages] = useState<KeyValuePair<string>[]>(
    Array.from({ length: 8 }, (_, index) => {
      return {
        key: locales.labels.tabs[index],
        value: 'neutral',
      };
    }),
  );

  useEffect(() => {
    if (!caseDetails) {
      return;
    }
    if (!documentRefreshRequired) {
      return;
    }
    setDocumentRefreshRequired(false);

    const vis = Enumerations.getDocumentVisibilityState(intl.locale);
    DocumentActions.findDocumentsOnCaseAsync(
      {
        caseId: caseDetails!.id,
        relatedExternalUserId: caseDetails?.caseIdentifiers.customerAccountId!,
      },
      getHeaders(userClaims, instance),
    ).then((result) => {
      if (result.isFailure) {
        setErrorMessages(locales.errorDetails.errors.documentLoadError);
        return;
      }

      setCaseDocuments(
        result.result!.map((doc) => {
          const date = new Date(doc.createdTimestamp);
          console.log(date);
          return {
            documentId: doc.id,
            isExpanded: false,
            visibility:
              vis.find((v) => v.key === doc.documentVisibility)?.value || '',
            caseId: doc.caseId,
            userId: doc.relatedExternalUserId,
            documentName: doc.userSpecifiedFileName,
            uploadedBy: `${doc.uploadedByUser.name} ${doc.uploadedByUser.surname}`,
            uploadedByInt: UserCore.getUserInitials(doc.uploadedByUser),
            uploadedOnTime: DateHelpers.getLocalTimeString(date, intl),
            uploadedOnDate: DateHelpers.getLocalDateString(date, intl, 'MMMM'),
            timeSinceUpload: getTimeSince(new Date(doc.createdTimestamp)),
            note: doc.accompanyingNotes,
          };
        }),
      );
    });
  }, [caseDetails, documentRefreshRequired]);

  useEffect(() => {
    if (!caseDetails) {
      return;
    }
    if (!noteRefreshRequired) {
      return;
    }
    setNoteRefreshRequired(false);

    CaseUtils.findCaseNotesForCaseAsync(
      caseDetails?.id,
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!,
    ).then((notes) => {
      if (notes.isFailure) {
        setErrorMessages(locales.errorDetails.errors.noteLoadError);
        return;
      }

      setCaseNotes(notes.result!);
    });
  }, [caseDetails, noteRefreshRequired]);

  useEffect(() => {
    if (!id) {
      setErrorMessages(locales.errorDetails.errors.caseNotFound);
      return;
    }
    if (!refreshRequired) {
      return;
    }
    setRefreshRequired(false);

    CaseUtils.getCaseAsync(
      userClaims?.user!,
      instance.getActiveAccount()!.idToken!,
      id,
    ).then((result) => {
      setLoading(false);
      UserCore.isInRoleAsync(userClaims!.user!, [
        LocalEnumerations.Roles.CaseManager,
        LocalEnumerations.Roles.SuperUser,
      ]).then((rst) => {
        if (rst.isFailure === true) {
          setErrorMessages(locales.errorDetails.errors.caseNotFound);
          return;
        }
      });
      if (result.isFailure || !result.result) {
        setErrorMessages(locales.errorDetails.errors.caseNotFound);
        return;
      }
      setCaseDetails(result.result!);
    });
    context?.onEvent(locales.breadcrumbs);
  }, [refreshRequired]);

  const isCaseInActiveServiceDelivery = (): boolean => isCasePastStatus(LocalEnumerations.CaseStatuses.ActiveServiceDeliveries);
  const isCaseInAwaitingPrerequisites = (): boolean => isCasePastStatus(LocalEnumerations.CaseStatuses.AwaitingPrerequisites);

  const isCasePastStatus = (minimumStatus: number): boolean => {
    if (!caseDetails) {
      return false;
    }

    const status = getCurrentStatus(caseDetails.statusHistory);
    const caseStatus = caseStatuses.find((x) => x.key === status.value);
    const minimumCaseStatus = caseStatuses.find(
      (x) => x.key === minimumStatus,
    );

    if (!caseStatus || !minimumCaseStatus) {
      return false;
    }

    return caseStatus.order >= minimumCaseStatus.order;
  };

  return loading ? (
    <PageLoader alt={locales.common.load} />
  ) : errorProps.show ? (
    <CommonModal
      headerText={errorProps.headerText}
      bodyText={errorProps.bodyText}
      buttonDetails={errorProps.buttonDetails}
      children={errorProps.children}
      show={errorProps.show}
    />
  ) : (
    <>
      <div className="Main-Form-Layout">
        <PillControl
          pageChangeAction={(oldPage, newPage) => setPageNumber(newPage)}
          pages={[
            {
              name: pageStates[0].key,
              mode: pageStates[0].value as ControlState,
              enabled: true,
              orderNo: 1,
              showAsOrdered: true,
              content: <div>Summary</div>,
            },
            {
              name: pageStates[1].key,
              mode: pageStates[1].value as ControlState,
              enabled: true,
              orderNo: 2,
              showAsOrdered: true,
              content: (
                <>
                  <h3>Referral</h3>
                </>
              ),
            },
            {
              name: pageStates[2].key,
              mode: pageStates[2].value as ControlState,
              enabled: true,
              orderNo: 3,
              showAsOrdered: true,
              content: (
                <>
                  <CaseServiceTab
                    intl={intl}
                    caseDetails={caseDetails}
                    labels={locales.labels.caseServicesTab}
                    instance={instance}
                    userClaims={userClaims}
                    pagingDetails={{
                      currentPageSize: userSettings.startingPageSize,
                      pageSizes: userSettings.pageSizes,
                    }}
                    events={{
                      requiresProofing: async (id, assessorId) => {
                        const rst =
                          await ServicesToFulfillActions.requireProofingAsync(
                            getHeaders(userClaims, instance),
                            id,
                            assessorId,
                            caseDetails!.id,
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      reportReviewComplete: async (id) => {
                        const rst =
                          await ServicesToFulfillActions.reportReviewCompleteAsync(
                            getHeaders(userClaims, instance),
                            id,
                            caseDetails!.id,
                          );
                        setRefreshRequired(true);
                        return rst;
                      },
                      onError: (message) => {
                        setErrorMessages(message, () => {
                          setErrorProps((prevData) => ({
                            ...prevData,
                            show: false,
                          }));
                        });
                      },
                      setRefreshRequired: () => setRefreshRequired(true),
                    }}
                  />
                </>
              ),
            },
            {
              name: pageStates[3].key,
              mode: pageStates[3].value as ControlState,
              enabled: isCaseInAwaitingPrerequisites(),
              orderNo: 4,
              showAsOrdered: true,
              content: (
                <>
                  <h3>Pre-Requisites</h3>
                  <InformationButton
                    key={`action_button_Triage`}
                    buttonDetails={{
                      label: 'Triage',
                      itemKey: `action_button_Triage`,
                      mode: 'positive',
                      clickEvent: () => {
                        navigate(`/cases/referral/${id}/triage`);
                      },
                    }}
                  >
                    <>
                      This is a temporary control to allow users triage the case
                      <br />
                      This functionality will be in this tab.
                    </>
                  </InformationButton>
                </>
              ),
            },
            {
              name: pageStates[4].key,
              mode: pageStates[4].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 5,
              showAsOrdered: true,
              content: (
                <>
                  <AppointmentsTab
                    caseId={id!}
                    isCaseMangerOrHigher={UserCore.userIsCaseManagerOrHigher(
                      userClaims?.user!,
                    )}
                    labels={locales.labels.appointmentTab}
                    instance={instance}
                    user={userClaims}
                    key={id!}
                    reload={false}
                    pagingDetails={{
                      currentPageSize: userSettings.startingPageSize,
                      pageSizes: userSettings.pageSizes,
                    }}
                    maxAdvancedMonths={userSettings.monthsInAdvance}
                    services={
                      caseDetails?.servicesToFulfill
                        .filter((s) => isAppointmentService(s.statusHistory))
                        .flatMap((serviceToFulFill) => {
                          return serviceToFulFill.appointments === undefined ||
                            serviceToFulFill.appointments === null ||
                            serviceToFulFill.appointments.length === 0
                            ? [
                                {
                                  isExpanded: false,
                                  rowId: serviceToFulFill.id,
                                  serviceToFulfillId: serviceToFulFill.id,
                                  serviceDefinitionId:
                                    serviceToFulFill.serviceDefinition!.id,
                                  serviceName:
                                    ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
                                      serviceToFulFill.serviceDefinition!,
                                      formats,
                                      conditions,
                                    ).value,
                                  duration:
                                    serviceToFulFill.serviceDefinition
                                      ?.assessmentAppointmentDefinition
                                      ?.duration,
                                },
                              ]
                            : getAppointments(serviceToFulFill);
                        }) || []
                    }
                    events={{
                      onBookingComplete: () => setRefreshRequired(true),
                      onError: (message) => {
                        setErrorMessages(message, () => {
                          setErrorProps((prevData) => ({
                            ...prevData,
                            show: false,
                          }));
                        });
                      },
                      setRefreshRequired: () => {
                        setRefreshRequired(true)},
                    }}
                  />
                </>
              ),
            },
            {
              name: pageStates[5].key,
              mode: pageStates[5].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 6,
              showAsOrdered: true,
              content: (
                <BillableItemsTab
                  intl={intl}
                  setRefreshRequired={() => setRefreshRequired(true)}
                  canAddBillableItems={canAddBillableItems()}
                  servicesToFulfill={caseDetails!.servicesToFulfill
                    .filter(
                      (x) =>
                        x.serviceDefinition?.assessmentAppointmentDefinition ===
                          null &&
                        getCurrentStatus(x.statusHistory).value ===
                          LocalEnumerations.ServiceStatuses.RequiresFulfillment,
                    )
                    .map((service) => {
                      return {
                        ...service,
                        serviceName:
                          ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
                            service.serviceDefinition!,
                            formats,
                            conditions,
                          ).value,
                        billableOrganisationName:
                          caseDetails!.defaultBillingOrganisation.name,
                      };
                    })}
                  labels={locales.labels.billableTab}
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  eventHandlers={{
                    onError: (message) => {
                      setErrorMessages(message);
                    },
                  }}
                  data={
                    caseDetails?.servicesToFulfill.flatMap((service) => {
                      return (
                        service.billableItems?.map((item) => {
                          return {
                            name: ServiceDefinitionActions.retrieveServiceDefinitionDescriptor(
                              service.serviceDefinition!,
                              formats,
                              conditions,
                            ).value,
                            itemID: item.id,
                            serviceCode: item.serviceCode,
                            price: item.unitPrice,
                            originalPrice: item.originalUnitPrice,
                            taxAmount: item.taxAmount,
                            originalTaxAmount: item.originalTaxAmount,
                            invoiceDate: item.invoiceDate
                              ? new Date(item.invoiceDate)
                              : null,
                            invoiceDueDate: item.invoiceDueDate
                              ? new Date(item.invoiceDueDate)
                              : null,
                            invoiceRaised: item.isInvoiced,
                            currency: item.currency,
                            isExpanded: false,
                            nominalCode: item.nominalCode,
                            dateCreated: new Date(item.createdDateTime),
                            billableOrganisationName:
                              caseDetails.defaultBillingOrganisation.name,
                            note: item.note,
                            invoiceNumber: item.invoiceNumber,
                            serviceToFulfillId: item.serviceToFulfillId,
                            caseId: item.caseId,
                            isEditing: false
                          };
                        }) || []
                      );
                    }) || []
                  }
                />
              ),
            },
            {
              name: pageStates[6].key,
              mode: pageStates[6].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 7,
              showAsOrdered: false,
              content: (
                <DocumentTab
                  labels={locales.labels.documentTab}
                  caseId={caseDetails?.id || ''}
                  locale={intl}
                  loader={documentFlicker}
                  rows={caseDocuments}
                  headerInfo={getHeaders(userClaims, instance)}
                  load={pageNumber === 6}
                  customerId={caseDetails?.customer.id || ''}
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  events={{
                    uploadDocument: async (document) => {
                      document.RelatedExternalUserId = caseDetails?.customer.id;
                      var rst = await DocumentUtils.uploadDocumentAsync(
                        document,
                        getHeaders(userClaims, instance),
                      );
                      if (rst.isFailure) {
                        setErrorMessages('Unable to save file', () => {
                          setErrorProps((prevData) => ({
                            ...prevData,
                            show: false,
                          }));
                        });
                      }
                      setDocumentFlicker(!documentFlicker);
                      return rst;
                    },
                    onError: (message) => {
                      setErrorMessages(message, () => {
                        setErrorProps((prevData) => ({
                          ...prevData,
                          show: false,
                        }));
                      });
                    },
                    refreshDocuments: () => setDocumentRefreshRequired(true),
                  }}
                />
              ),
            },
            {
              name: pageStates[7].key,
              mode: pageStates[7].value as ControlState,
              enabled: isCaseInActiveServiceDelivery(),
              orderNo: 8,
              showAsOrdered: false,
              content: (
                <NotesTab
                  labels={locales.labels.notesTab}
                  pagingDetails={{
                    currentPageSize: userSettings.startingPageSize,
                    pageSizes: userSettings.pageSizes,
                  }}
                  events={{
                    onError: (message) => {
                      setErrorMessages(message);
                    },
                    refreshNotes: () => setNoteRefreshRequired(true),
                    onCreateNote: async (note: CaseNote_Types.Base) => {
                      const result = await CaseUtils.addCaseNoteToCaseAsync(
                        note,
                        userClaims?.user!,
                        instance.getActiveAccount()!.idToken!,
                      );
                      if (result.isFailure) {
                        setErrorMessages(
                          locales.errorDetails.errors.noteSaveError,
                        );
                        return result;
                      }

                      setNoteRefreshRequired(true);
                      return result;
                    },
                  }}
                  associatedDocuments={caseDocuments.map((doc) => {
                    return {
                      key: doc.documentId,
                      value: doc.documentName,
                    };
                  })}
                  caseId={caseDetails!.id}
                  rows={
                    caseNotes?.map((note) => {
                      return {
                        itemID: note.id,
                        content: note.content,
                        createdDateTime: note.createdDateTime,
                        modifiedDateTime: note.modifiedDateTime,
                        createdByName: `${note.createdByUser.name} ${note.createdByUser.surname}`,
                        isExpanded: false,
                        documentName:
                          caseDocuments.find(
                            (x) => x.documentId === note.documentId,
                          )?.documentName || '',
                        createdByInitials: UserCore.getUserInitials(
                          note.createdByUser,
                        ),
                      };
                    }) || []
                  }
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default CaseContainerPage;
