// src/pages/Dashboard/Dashboard.tsx
import React, { useEffect, useContext, useState } from 'react';
import { TaskActions } from '../../../systemUtils/task/TaskActions';
import { useIntl } from 'react-intl';
import  Enumerations  from '../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import { TaskItemProps } from './tasks/taskItem/TaskItem.types';
import TaskList from './tasks/taskList/TaskList';
import { UserClaimsContext } from '../../sharedControls/contexts/UserClaimsContext';
import { KeyValuePair } from '../../../sysObjects/common.types';

const ViewTasks: React.FC = () => {
  const intl = useIntl();
  const messages = require(`./locales/${intl.locale}.json`);

  const [taskList, setTaskList] = React.useState<TaskItemProps[]>([]);
  const [taskStatuses, setStatuses] = useState<KeyValuePair<number>[]>([]);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string>('');
  const { userClaims } = useContext(UserClaimsContext);
  const [taskStatusesLoaded, setTaskStatusesLoaded] = useState<boolean>(false);
  const [refreshRequired, setRefreshStatus] = React.useState<boolean>(false);
  const { instance } = useMsal();

  useEffect(() => {
    setStatuses(Enumerations.getTaskStatuses(intl.locale));
    setTaskStatusesLoaded(true);
  }, []);

  useEffect(() => {
    if (taskStatusesLoaded) {
      setLoading(true);
      TaskActions.listTasksAsync(
        {
          assignedUserAccountId: userClaims!.user!.userAccountId,
        },
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!
      )
        .then((taskResult) => {
          setLoading(false);
          if (taskResult.isFailure) {
            setError(messages.errorMessage);
            return;
          }

          if (!taskResult.result || taskResult.result.length === 0) {
            setError(messages.noTasks);
            return;
          }

          setTaskList(
            taskResult.result!.map((task) => {
              let status = taskStatuses.find((x) => x.key === task.status);

              return {
                id: task.id,
                headerLabel: task.label,
                notes: [task.text],
                createdBy: task.assignedByUserFullName,
                completed: task.completedDateTime !== null,
                completedDate: task.completedDateTime,
                raisedDate:task.createdDateTime,
                dueDate: task.dueDate ,
                caseReference: task.caseReference,
                status: status?.value ?? 'Unknown',
                completable: task.completable,
                controlState: task.completedDateTime
                  ? 'positive'
                  : task.dueDate && new Date() > new Date(task.dueDate)
                  ? 'negative'
                  : 'neutral',
              };
            })
          );

          setRefreshStatus(false);
        })
        .catch((error) => {
          setError(messages.errorMessage);
        });
    }
  }, [taskStatusesLoaded, userClaims, taskStatuses, refreshRequired]);

  return (
    <TaskList
      Label="Tasks"
      tasks={taskList}
      key="tasks"
      isLoading={loading}
      errorMessage={error}
      CompleteTicked={(id) => {
        TaskActions.markTaskCompleteAsync(
          id,
          userClaims!.user!,
          instance.getActiveAccount()!.idToken!
        ).then((result) => {
          if (result.isFailure) {
            setError(messages.errorMessage);
            return;
          }
          setTaskList((prevTaskList) =>
            prevTaskList.map((task) =>
              task.id === id ? { ...task, completed: true } : task
            )
          );
        });
        setRefreshStatus(true);
      }}
    />
  );
};

export default ViewTasks;
