import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/userUtils/ExternalUserActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import ExternalUser_Types from '../../../sysObjects/apiModels/ExternalUser.types';
import { useMsal } from '@azure/msal-react';
import {
  getHeaders,
  LocalEnumerations,
} from '../../../systemUtils/common/CommonHelpers';
import './ExternalUsersIndex.css';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import ExternalUsers_IndexTypes from './ExternalUsersIndex.types';
import ButtonBoxTypes from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox.types';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';

const ExternalUsers: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { userSettings } = useUserSettingsContext();
  const context = React.useContext(CrumbUpdateContext);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [reload, setReload] = React.useState<boolean>(true);
  const [errorProps, setErrorProps] = React.useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });
  const [intUsers, setIntUsers] = React.useState<
    ExternalUsers_IndexTypes.rowData[]
  >([]);

  const setErrorMessages = (message: string, ent?: () => void) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      bodyText: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  React.useEffect(() => {
    if (!reload) {
      return;
    }

    setReload(false);
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      navigate('/');
      return;
    }

    actions
      .findUsersAsync(getHeaders(userClaims, instance), {
        status: [
          LocalEnumerations.UserStatuses.Active,
          LocalEnumerations.UserStatuses.Invited,
          LocalEnumerations.UserStatuses.Disabled,
        ],
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          setErrorMessages(locales.errorDetails.loadingFailed);
          return;
        }

        setIntUsers(
          result.result.map((usr: ExternalUser_Types.listItem) => {
            return {
              id: usr.id,
              data: [
                `${usr.surname}, ${usr.name}`,
                locales.roles[usr.role || 4],
                locales.statuses[usr.status || 0],
              ],
              obj: usr,
              isChecked:
                usr.status === LocalEnumerations.UserStatuses.Active
                  ? false
                  : undefined,
              buttonColumn: (
                <ButtonBox
                  id={`user-${usr.id}`}
                  key={`user-${usr.id}`}
                  displayBorder={false}
                  buttons={DetermineUserButtons(
                    usr,
                    usr.status === LocalEnumerations.UserStatuses.Active
                      ? false
                      : undefined
                  )}
                />
              ),
            };
          })
        );
      });
    context?.onEvent(locales.breadcrumbs);
  }, [reload]);

  const DetermineUserButtons = (
    user: ExternalUser_Types.listItem,
    checked?: boolean
  ): ButtonBoxTypes.Button[] => {
    let buttons: ButtonBoxTypes.Button[] = [];

    if (user.status !== LocalEnumerations.UserStatuses.Disabled) {
      buttons.push({
        id: `edit-${user.id}`,
        label: locales.statusButtons.edit,
        controlState: 'positive',
        onClick: () => {
          navigate(
            `/external/users/edit/${locales.roles[user.role]}/${user.id}`
          );
        },
      });
      if(user.role === LocalEnumerations.Roles.Customer && UserCore.userIsCaseManagerOrHigher(userClaims!.user!)){
        buttons.push({
          id: `createProfile-${user.id}`,
          label: user.customerId == null ? locales.statusButtons.createProfile : locales.statusButtons.editProfile,
          controlState: 'positive',
          onClick: () => {
            const path = `/external/users/Customer/${user.id}/profile`;
            navigate(user.customerId == null ? `${path}/create` : `${path}/${user.customerId}`);
          },
        });
      }

      if(user.status === LocalEnumerations.UserStatuses.Invited){
        buttons.push({
          id: `resendInvite-${user.id}`,
          controlState: 'positive',
          label: locales.statusButtons.resend,
          isChecked: checked,
          onClick: () => {
            actions.sendRefreshToken(
              userClaims!.user!,
              instance.getActiveAccount()!.idToken!,
              user.id
            );
          }
        });
      }
    } else {
      buttons.push({
        id: `changeState-${user.id}`,
        controlState: 'positive',
        label: locales.statusButtons.enable,
        isChecked: checked,
        onClick: () => {
          updateStatus(user, LocalEnumerations.BasicEntityStatus.Active);
        },
      });
    }
    if (user.status !== LocalEnumerations.UserStatuses.Active) {
      return buttons;
    }

    buttons.push({
      id: `changeState-${user.id}`,
      controlState: 'warning',
      label: locales.statusButtons.disable,
      isChecked: checked,
      onClick: () => {
        setIntUsers((prevData) => {
          const newData = [...prevData];
          const index = newData.findIndex((item) => item.id === user.id);
          newData[index].buttonColumn = (
            <div className="InternalUser-Confirm-Box">
              <ButtonBox
                id={`user-${user.id}`}
                key={`user-${user.id}`}
                displayBorder={false}
                buttons={DetermineUserButtons(user, true)}
              />
              <div className="InternalUser-Confirm-Box-Text">
                <span>{locales.statusButtons.check}</span>
                <ButtonBox
                  id={`confirm-user-${user.id}-render`}
                  key={`confirm-user-${user.id}-render`}
                  displayBorder={true}
                  buttons={[
                    {
                      id: `confirm-user-${user.id}-yes`,
                      controlState: 'negative',
                      label: locales.statusButtons.confirm,
                      onClick: () => {
                        updateStatus(
                          user,
                          LocalEnumerations.BasicEntityStatus.Deactivated
                        );
                      },
                    },
                    {
                      id: `confirm-user-${user.id}-no`,
                      controlState: 'positive',
                      label: locales.statusButtons.cancel,
                      onClick: () => {
                        setIntUsers((prevData) => {
                          const newData = [...prevData];
                          const index = newData.findIndex(
                            (item) => item.id === user.id
                          );
                          newData[index].buttonColumn = (
                            <ButtonBox
                              id={`user-${user.id}-confirmation`}
                              key={`user-${user.id}-confirmation`}
                              displayBorder={false}
                              buttons={DetermineUserButtons(user, false)}
                            />
                          );
                          return newData;
                        });
                      },
                    },
                  ]}
                />
              </div>
            </div>
          );
          return newData;
        });
      },
    });

    return buttons;
  };

  const updateStatus = (user: ExternalUser_Types.listItem, state: number) => {
    actions
      .setUserState(getHeaders(userClaims, instance), user.id, state)
      .then((result) => {
        if (result.isFailure) {
          setErrorMessages(locales.errorDetails.editState, () => {
            setErrorProps((prevData) => ({
              ...prevData,
              show: false,
            }));
          });
          return;
        }
        setIsLoading(true);
        setReload(true);
      });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : !errorProps.show ? (
    <div className="Main-Form-Layout">
      <CommonTable
        className="ExternalUser-Table"
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={intUsers}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableHeading,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'info-button-customer',
            label: locales.createCustomer.button,
            mode: 'positive',
            clickEvent: () => navigate('/external/users/create/customer'),
          }}
        >
          {locales.createCustomer.summary}
        </InformationButton>
        <InformationButton
          buttonDetails={{
            itemKey: 'info-button-referrer',
            label: locales.createReferrer.button,
            mode: 'positive',
            clickEvent: () => navigate('/external/users/create/referrer'),
          }}
        >
          {locales.createReferrer.summary}
        </InformationButton>
      </div>
    </div>
  ) : (
    <CommonModal
      headerText={errorProps.headerText}
      bodyText={errorProps.bodyText}
      buttonDetails={errorProps.buttonDetails}
      show={errorProps.show}
    />
  );
};

export default ExternalUsers;
