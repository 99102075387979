import { createBrowserRouter } from 'react-router-dom';
import { Pages, Layouts } from './pageViews/PageImports';

const notAuthRoutes = [
  {
    path: '/',
    element: <Pages.Landing />,
  },
  {
    path: '/logout',
    element: <Pages.Landing />,
  },
  {
    path: '/passback',
    element: <Pages.Loading />,
  },
];

const unauthorisedRoutes = [
  {
    path: '/passback',
    element: <Pages.UnauthorisedUser />,
  },
];

const authRoutes = [
  {
    path: '/passback',
    element: <Pages.Loading />,
  },
  {
    path: '/',
    element: <Layouts.BaseLayout />,
    errorElement: <Pages.Error />,
    children: [
      {
        index: true,
        element: <Pages.Home />,
      },
      {
        path: 'internal/users',
        pathname: 'internal-users',
        children: [
          {
            index: true,
            element: <Pages.ListInternalUsers />,
          },
          {
            path: 'create/:role',
            element: <Pages.EditInternalUser />,
          },
          {
            path: 'edit/:role/:id',
            element: <Pages.EditInternalUser />,
          },
        ],
      },

      {
        path: 'External/users',
        children: [
          {
            index: true,
            element: <Pages.ListExternalUsers />,
          },
          {
            path: 'create/Customer',
            element: <Pages.ExternalUser />,
          },
          {
            path: 'create/Customer/:id',
            element: <Pages.ExternalUser />,
          },
          {
            path: 'Edit/Customer/:id',
            element: <Pages.ExternalUser />,
          },
          {
            path: 'create/:role',
            element: <Pages.EditInternalUser />,
          },
          {
            path: 'edit/:role/:id',
            element: <Pages.EditInternalUser />,
          },
          {
            path: 'customer/:accountId/profile/:id',
            element: <Pages.CreateUpdateProfile />,
          },
          {
            path: 'customer/:accountId/profile/create',
            element: <Pages.CreateUpdateProfile />,
          },
        ],
      },
      {
        path: '/organisations',
        children: [
          {
            index: true,
            element: <Pages.Organisation />,
          },
          {
            path: 'create',
            element: <Pages.CreateOrg />,
          },
          {
            path: ':id/edit',
            element: <Pages.CreateOrg />,
          },
          {
            path: ':orgId/billing/create/',
            element: <Pages.CreateBilling />,
          },
          {
            path: ':orgId/billing/',
            element: <Pages.BillingIndex />,
          },
          {
            path: ':orgId/billing/edit/:id',
            element: <Pages.CreateBilling />,
          },
          {
            path: ':orgId/billing/:billingId/sla',
            element: <Pages.SlaDefinition />,
          },
          {
            path: ':orgId/billing/:billingId/sla/create',
            element: <Pages.CreateSlaDefinition />,
          },
          {
            path: ':orgId/billing/:billingId/sla/edit/:id',
            element: <Pages.CreateSlaDefinition />,
          },
        ],
      },
      {
        path: 'services',
        children: [
          {
            index: true,
            element: <Pages.ListServiceDefinition />,
          },
          {
            path: 'create',
            element: <Pages.CreateServiceDefinition />,
          },
          {
            path: 'edit/:id',
            element: <Pages.CreateServiceDefinition />,
          },
          {
            path: ':serviceId/appointmentTemplate/create/',
            element: <Pages.AA_CU />,
          },
          {
            path: ':serviceId/appointmentTemplate/edit/:id',
            element: <Pages.AA_CU />,
          },
        ],
      },
      {
        path: '/pricing',
        children: [
          {
            index: true,
            element: <Pages.ListPricingDefinition />,
          },
          {
            path: 'create',
            element: <Pages.EditPricingDefinition />,
          },
          {
            path: 'Edit/:id',
            element: <Pages.EditPricingDefinition />,
          },
        ],
      },
      {
        path: 'slaDefinitions',
        element: <Pages.SlaDefinition />,
      },
      {
        path: 'me/Consent',
        element: <Pages.ConsentView displayAgreement={false} />,
      },
      {
        path: '/cases',
        children: [
          {
            index: true,
            element: <Pages.CaseIndex />,
          },
          {
            path: 'view/:id',
            element: <Pages.CaseContainer />,
          },
          {
            path: 'referral/create',
            element: <Pages.CreateReferral />,
          },
          {
            path: 'referral/:caseId/triage',
            element: <Pages.CreateTriage />,
          },
        ],
      },
    ],
  },
];

export const authRouter = createBrowserRouter(authRoutes);
export const notAuthRouter = createBrowserRouter(notAuthRoutes);
export const unauthorisedRouter = createBrowserRouter(unauthorisedRoutes);
