import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../../systemUtils/slaDefinition/SlaDefinitionActions';
import { useNavigate, useParams } from 'react-router-dom';
import UserCore from '../../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import { UserClaimsContext } from '../../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonModalTypes from '../../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import CommonTableTypes from '../../../../systemComponents/sharedControls/tables/commonTable/CommonTable.types';
import ButtonBox from '../../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import PageLoader from '../../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import CommonModal from '../../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';

const SLA_Definition_Index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { userSettings } = useUserSettingsContext();

  const { orgId, billingId } = useParams();
  const { userClaims } = React.useContext(UserClaimsContext);
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorProps, setErrorProps] = React.useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });
  const [list, setList] = React.useState<CommonTableTypes.DataRow[]>([]);

  const setErrorMessages = (message: string, ent?: () => void) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      bodyText: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  React.useEffect(() => {
    if (!orgId || !billingId) {
      setErrorMessages(locales.errorDetails.loadingFailed);
      return;
    }
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }

    actions
      .findAsync(userClaims!.user!, instance.getActiveAccount()!.idToken!, {
        orgId: orgId,
      })
      .then((result) => {
        setIsLoading(false);
        if (result.isFailure || !result.result) {
          setErrorMessages(locales.errorDetails.loadingFailed);
          return;
        }

        setList(
          result.result.map((li) => {
            return {
              id: li.id!,
              data: [li.name, li.description],
              buttonColumn: (
                <ButtonBox
                  id={`sla-${li.id}`}
                  key={`sla-${li.id}`}
                  displayBorder={false}
                  buttons={[
                    {
                      id: `edit-${li.id}`,
                      controlState: 'positive',
                      label: locales.editButton,
                      onClick: () =>
                        navigate(
                          `/organisations/${orgId}/billing/${billingId}/sla/edit/${li.id}`
                        ),
                    },
                  ]}
                />
              ),
            };
          })
        );
      });
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : !errorProps.show ? (
    <div className="Main-Form-Layout">
      <CommonTable
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={list}
        labels={{
          of: locales.of,
          size: locales.size,
          tableName: locales.tableName,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'slaCreate',
            label: locales.createButton,
            mode: 'positive',
            clickEvent: () =>
              navigate(
                `/organisations/${orgId}/billing/${billingId}/sla/create`
              ),
          }}
        >
          {locales.createSummary}
        </InformationButton>
      </div>
    </div>
  ) : (
    <CommonModal
      headerText={errorProps.headerText}
      bodyText={errorProps.bodyText}
      buttonDetails={errorProps.buttonDetails}
      show={errorProps.show}
    />
  );
};

export default SLA_Definition_Index;
