import React from 'react';
import { useNavigate } from 'react-router-dom';
const Organisational_Billing_Index: React.FC = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    navigate('/organisations');
  }, []);
  return <>Place Holder: For crumb</>;
};

export default Organisational_Billing_Index;
