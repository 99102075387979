import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import {
  fetchAction,
  updateAction,
} from '../../systemUtils/common/APIHelper';
import { TaskTypes } from '../../sysObjects/apiModels/TaskTypes.types';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import { IUserClaims } from '../../sysObjects/UserClaims.types';

export namespace TaskActions {
  /**
   * Gets a list of Service Definitions.
   * @param obj The query object
   * @param performingUser The user performing the action
   * @returns The result of the action
   */
  export const listTasksAsync = async (
    obj: TaskTypes.queryTaskList,
    performingUser: IUserClaims,
    accessToken: string
  ): Promise<FunctionalResult<TaskTypes.TaskListItem[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    let query: string[] = [];

    if (obj.caseId) {
      query.push(`${settings.Tasks.query.queryCase}=${obj.caseId}`);
    }

    if (obj.statuses) {
      obj.statuses.forEach((status) => {
        query.push(`${settings.Tasks.query.queryStatus}=${status}`);
      });
    }

    if (obj.completable !== undefined && obj.completable !== null) {
      query.push(`${settings.Tasks.query.queryCompletable}=${obj.completable}`);
    }

    let queryString = query.join('&');

    if (queryString !== '') {
      queryString = `?${queryString}`;
    }

    return fetchAction({
      userId: performingUser.userAccountId,
      hostPath: host!.result!.path,
      apiPath: `${settings.Tasks.query.path}${obj.assignedUserAccountId}${queryString}`,
      accessToken: accessToken,
    });
  };

  export const markTaskCompleteAsync = async (
    id: string,
    performingUser: IUserClaims,
    accessToken: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return updateAction({
      userId: performingUser.userAccountId,
      hostPath: host!.result!.path,
      apiPath: `${settings.Tasks.markComplete.path}${id}`,
      formData: {},
      accessToken: accessToken,
    });
  };
}
