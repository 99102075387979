import React from 'react';
import BaseBox from '../../reusableBlocks/baseBox/BaseBox';
import CommonModalTypes from './CommonModal.types';

import './CommonModal.css';

const CommonModal: React.FC<CommonModalTypes.Props> = (props) => {
  return (
    <div hidden={!props.show} className={`modal negative`}>
      <div className="modal-content">
        <BaseBox
          modalKey="modelKey"
          bodyText={props.bodyText}
          heading={props.headerText}
          controlState="negative"
          primaryButton={props.buttonDetails}
        >
          {props.children}
        </BaseBox>
      </div>
    </div>
  );
};

export default CommonModal;
