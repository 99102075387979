import Organisation_Types from '../../sysObjects/apiModels/Organisation.types';
import { IPublicClientApplication } from '@azure/msal-browser';
import System_Types from '../../sysObjects/apiModels/System.types';
import StatusHistory from '../../sysObjects/apiModels/StatusHistory.types';
import ServiceLevelAgreement from '../../sysObjects/apiModels/ServiceLevelAgreement.Types';
import moment from 'moment';
import { KeyValuePair, OrderedKeyValuePair, TimeSpan } from '../../sysObjects/common.types';
import { SystemUserClaims } from '../../systemComponents/sharedControls/contexts/UserClaimsContextType.types';
import { FunctionalResult } from '../../sysObjects/FunctionalResult';

/**
 *  Return the current date, this is a hook for testing purposes.
 * @returns Current date.
 */
export const getDate = (): Date => {
  return new Date();
};

/**
 * Gets all the appointment formats in the system
 * @returns FunctionalResult<KeyValuePair<number>[]>
 */
export const getAppointmentFormats = (
  lang: string
): OrderedKeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/AppointmentFormats.json`);
};

/**
 * Gets a list of Case Statuses.
 * @returns The result of the action
 */
export const getCaseStatuses = (
  lang: string
): OrderedKeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/CaseStatuses.json`);
};

/**
 * Gets all the disability's in the system
 * @returns KeyValuePair<number>[]
 */
export const getDeliveryFormats = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/DeliveryFormats.json`);
};

/**
 * Gets all the disability's in the system
 * @returns KeyValuePair<number>[]
 */
export const getDisabilities = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/Disabilities.json`);
};

export const getDocumentTypes = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/DocumentTypes.json`);
};

export const getDocumentVisibilityState = (
  lang: string
): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/DocumentVisibilityState.json`);
};

/**
 * Gets all the employment type's in the system
 * @returns KeyValuePair<number>[]
 */
export const getEmploymentTypes = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/EmploymentTypes.json`);
};

/**
 * Gets all the ethnicity's in the system
 * @returns KeyValuePair<number>[]
 */
export const getEthnicity = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/Ethnicities.json`);
};

/**
 * Gets all the Gender's in the system
 * @returns KeyValuePair<number>[]
 */
export const getGender = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/Genders.json`);
};

/**
 * Gets all the Gender's in the system
 * @returns KeyValuePair<number>[]
 */
export const getPronouns = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/Pronouns.json`);
};

/**
 * Gets all the related conditions in the system
 * @returns KeyValuePair<number>[]>
 */
export const getRelatedConditions = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/RelatedConditions.json`);
};

/**
 * Gets all the roles's in the system
 * @param role  - The type of list to get
 * @returns KeyValuePair<number>[]
 */
export const getRoles = (
  role: UserRoles | '',
  lang: string
): KeyValuePair<number>[] => {
  const roles = require(`../../config/enums/${lang}/Roles.json`);
  if (role === '') {
    return roles.map((role: any) => {
      return {
        key: role.key,
        value: role.value,
      };
    });
  } else {
    return roles
      .filter((role: any) => role.type === role)
      .map((role: any) => {
        return {
          key: role.key,
          value: role.value,
        };
      });
  }
};

/**
 * Gets all the service statuses in the system
 * @returns KeyValuePair<number>[]
 */
export const getServiceStatuses = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/ServiceStatuses.json`);
};

/**
 * Gets all the suspected conditions in the system
 * @returns KeyValuePair<number>[]
 */
export const getSuspectedConditions = (
  lang: string
): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/SuspectedConditions.json`);
};

/**
 * Gets all the User Statuses in the system
 * @returns KeyValuePair<number>[]
 */
export const getUserStatuses = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/UserStatuses.json`);
};

/**
 * Gets all the organisational sizes in the system
 * @returns KeyValuePair[]
 */
export const getOrganisationSize = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/OrganisationSizes.json`);
};

/**
 * Gets all the organisational sizes in the system
 * @returns KeyValuePair[]
 */
export const getOrganisationType = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/OrganisationTypes.json`);
};

/**
 * Gets all the industry sectors in the system
 * @returns KeyValuePair[]
 */
export const getIndustrySectors = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/IndustrySectors.json`);
};
/**
 * Gets all the Referral reasons in the system
 * @returns KeyValuePair[]
 */
export const getReferralReasons = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/ReferralReason.json`);
};

/**
 * Gets all the services required in the system
 * @returns KeyValuePair[]
 */
export const getServiceRequired = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/ServiceRequired.json`);
};

/**
 * Gets all the currencies in the system
 * @returns KeyValuePair[]
 */
export const getSupportedCurrencies = (
  lang: string
): Organisation_Types.supportedCurrency[] => {
  return require(`../../config/enums/${lang}/SupportedCurrencies.json`);
};

/**
 * Gets all the taxes regimes in the system
 * @returns KeyValuePair[]
 */
export const getSupportedTaxes = (
  lang: string
): Organisation_Types.supportedTax[] => {
  return require(`../../config/enums/${lang}/SupportedTaxes.json`);
};

/**
 * Gets all the task statuses in the system
 * @returns FunctionalResult<KeyValuePair[]>
 */
export const getTaskStatuses = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/TaskStatus.json`);
};

/**
 * Gets all the task types in the system
 * @returns FunctionalResult<KeyValuePair[]>
 */
export const getTaskTypes = (lang: string): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/TaskType.json`);
};

/**
 * Gets all the appointment statuses in the system
 * @returns FunctionalResult<KeyValuePair[]>
 */
export const getAppointmentStatuses = (
  lang: string
): KeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/AppointmentStatuses.json`);
};

/**
 * Gets all the service fulfillment statuses in the system
 * @returns FunctionalResult<KeyValuePair[]>
 */
export const getServiceFulfillmentStatuses = (
  lang: string
): OrderedKeyValuePair<number>[] => {
  return require(`../../config/enums/${lang}/ServiceFulfillmentStatuses.json`);
};

const Enumerations = {
  getAppointmentFormats,
  getCaseStatuses,
  getDeliveryFormats,
  getDisabilities,
  getDocumentTypes,
  getDocumentVisibilityState,
  getEmploymentTypes,
  getEthnicity,
  getGender,
  getRelatedConditions,
  getPronouns,
  getRoles,
  getServiceStatuses,
  getSuspectedConditions,
  getUserStatuses,
  getOrganisationSize,
  getOrganisationType,
  getIndustrySectors,
  getReferralReasons,
  getServiceRequired,
  getSupportedCurrencies,
  getSupportedTaxes,
  getTaskStatuses,
  getTaskTypes,
  getAppointmentStatuses,
  getServiceFulfillmentStatuses,
};
export default Enumerations;

export const getServerErrors = (
  messages: any,
  codes?: number | number[]
): React.ReactNode => {
  try {
    if (codes === undefined) {
      return <>{messages.errorDetails.serverErrors.default} </>;
    }
    if (!Array.isArray(codes)) {
      codes = [codes];
    }

    let response: string[] = codes.map((code) => {
      let check = messages.errorDetails.serverErrors[`Code_${code.toString()}`];
      if (!check) {
        return messages.errorDetails.serverErrors.default;
      }
      return check;
    });

    response = response.filter((f, index, arr) => {
      return !!f && arr.indexOf(f) >= index;
    });

    if (response.length > 0) {
      return (
        <>
          {response.map((r, index) => {
            return (
              <>
                {r}
                {index !== response.length - 1 && <br />}
              </>
            );
          })}
        </>
      );
    }

    return <>{messages.errorDetails.default}</>;
  } catch (e) {
    return <>Unable to perform action</>;
  }
};

export const getHeaders = (
  user?: SystemUserClaims | null,
  instance?: IPublicClientApplication
): FunctionalResult<System_Types.ApiHeader> => {
  if (
    user === undefined ||
    user === null ||
    user!.user === undefined ||
    user!.user === null
  ) {
    return FunctionalResult.Error('User is not defined');
  }

  if (
    instance === undefined ||
    instance === null ||
    !instance.getActiveAccount
  ) {
    return FunctionalResult.Error('Instance is not defined');
  }

  var token = instance.getActiveAccount()!.idToken;
  if (token === undefined || token === null) {
    return FunctionalResult.Error('Token is not defined');
  }
  if (
    user.user.userAccountId === undefined ||
    user.user.userAccountId === null
  ) {
    return FunctionalResult.Error('User Account Id is not defined');
  }

  return FunctionalResult.Success({
    userId: user.user.userAccountId,
    token: token,
  });
};

export const timeSpanToTicks = (timeSpan: TimeSpan): number => {
  const ticksPerMillisecond = 10000;
  const ticksPerSecond = ticksPerMillisecond * 1000;
  const ticksPerMinute = ticksPerSecond * 60;
  const ticksPerHour = ticksPerMinute * 60;
  const ticksPerDay = ticksPerHour * 24;

  let ticks = 0;
  ticks += (timeSpan.days ?? 0) * ticksPerDay;
  ticks += (timeSpan.hours ?? 0) * ticksPerHour;
  ticks += (timeSpan.minutes ?? 0) * ticksPerMinute;
  ticks += (timeSpan.seconds ?? 0) * ticksPerSecond;
  ticks += (timeSpan.milliseconds ?? 0) * ticksPerMillisecond;

  return ticks;
};

export const parseTimeSpan = (input: string): TimeSpan => {
  const parts = input
    .split(':')
    .map((part, index) => {
      // For the first part, split by period if it exists to separate days.
      if (index === 0 && part.includes('.')) {
        return part.split('.').map(Number);
      }
      return Number(part);
    })
    .flat();

  const timeSpan: TimeSpan = {
    days: parts.length > 0 ? parts[0] : undefined,
    hours: parts.length > 1 ? parts[1] : undefined,
    minutes: parts.length > 2 ? parts[2] : undefined,
    seconds: parts.length > 3 ? parts[3] : undefined,
    milliseconds: parts.length > 4 ? parts[4] : undefined,
  };

  return timeSpan;
};

/**
 * Sorts a list of status history in descending order.
 * @param a The first status history.
 * @param b The second status history.
 * @returns The sort order.
 */
export const sortStatusHistoryDesc = (
  a: StatusHistory<number>,
  b: StatusHistory<number>
) => {
  const dateA = new Date(a.createdDateTime);
  const dateB = new Date(b.createdDateTime);
  return (dateB as any) - (dateA as any);
};

/**
 * Sorts a list of status history in ascending order.
 * @param a The first service level agreement.
 * @param b The second service level agreement.
 * @returns The sort order.
 */
export const sortServiceLevelAgreementAsc = (
  a: ServiceLevelAgreement,
  b: ServiceLevelAgreement
) => {
  const dateA = new Date(a.dueDate!);
  const dateB = new Date(b.dueDate!);
  return (dateA as any) - (dateB as any);
};

/**
 * Gets the current status of an object with status.
 * @param statuses The status history of an object with status.
 * @returns The current status of an object with status.
 */
export const getCurrentStatus = (
  statuses: StatusHistory<number>[]
): StatusHistory<number> => {
  return statuses.reduce((prev, current) =>
    prev.createdDateTime > current.createdDateTime ? prev : current
  );
};

export const getCurrencyString = (value: number, code: string): string => {
  return value.toLocaleString('en-GB', {
    style: 'currency',
    currency: code,
    currencyDisplay: 'symbol',
  });
};

export const getTimeSince = (incomingDate: Date): string => {
  let asMoment = moment(incomingDate);
  return `${asMoment.format('DD/MM/YYYY')} (${asMoment.fromNow()})`;
};

export const handleLogout = async (instance: IPublicClientApplication) => {
  const currentAccount = instance.getActiveAccount();
  await instance.logoutRedirect({
    account: currentAccount, 
    onRedirectNavigate: (url) => {
      return false;
    }
  });
}

export namespace LocalEnumerations {
  export enum Roles {
    SuperUser = 0,
    CaseManager = 1,
    Assessor = 2,
    AssociateAssessor = 3,
    Referrer = 4,
    Customer = 5,
  }

  export enum UserStatuses {
    Invited = 0,
    Active = 1,
    Disabled = 2,
  }

  export enum BasicEntityStatus {
    Active = 1,
    Deactivated = 0
  }

  export enum ServiceStatuses {
    AwaitingCostApproval = 0,
    RequiresFulfillment = 1,
    RequiresAppointment = 2,
    AppointmentOffered = 3,
    ReportRequired = 4,
    ReportReadyForReview = 5,
    FinalReportPending = 6,
    FinalReportComplete = 7,
    CustomerReviewPending = 8,
    ServiceComplete = 9,
    ServiceCancelled = 10,
  }

  export enum CaseStatuses {
    NewReferral = 0,
    CaseManagerAssigned = 1,
    AwaitingPrerequisites = 2,
    ActiveServiceDeliveries = 3,
    CaseComplete = 4,
    CaseCancelled = 5,
  }

  export enum AppointmentStatuses {
    Offered = 0,
    Confirmed = 1,
    Declined = 2,
    Cancelled = 3,
    LexxicNonAttendance = 4,
    CustomerNonAttendance = 5,
    Attended = 6,
  }

  export enum ServiceDefinitionStatuses{
    Active = 0,
    Inactive = 1
  }
}

type UserRoles = 'Internal' | 'External';
