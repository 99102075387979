import { FunctionalResult } from '../../sysObjects/FunctionalResult';
import {  fetchAction,
  createAction,
  updateAction,
} from '../../systemUtils/common/APIHelper';
import settings from '../../config/services.json';
import { getHostConfigAsync } from '../common/HostConfigActions';
import SlaDefinition_Types from '../../sysObjects/apiModels/SlaDefinition.types';
import { IUserClaims } from '../../sysObjects/UserClaims.types';

namespace SlaDefinitionActions {
  /**
   * Finds the object based on the lookup criteria.
   * @param {IUserClaims} The user performing the action.
   * @param {SlaDefinition_Types.findQuery}obj The Query object to search for.
   * @returns
   */
  export const findAsync = async (
    performingUser: IUserClaims,
    accessToken: string,
    obj: SlaDefinition_Types.findQuery
  ): Promise<FunctionalResult<SlaDefinition_Types.listItem[]>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.SlaDefinitions.find.path}/${obj.orgId}`,
      userId: performingUser.userAccountId,
      accessToken: accessToken,
    });
  };

  /**
   * Saves the object.
   * @param {IUserClaims} usr to pass to the calling method
   * @param {SlaDefinition_Types.slaDefinitionItem} The record to save.
   * @returns The result of the action
   */
  export const saveAsync = async (
    performingUser: IUserClaims,
    accessToken: string,
    obj: SlaDefinition_Types.slaDefinitionItem,
    id?: string
  ): Promise<FunctionalResult<string>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }
    if (!id) {
      return createAction({
        hostPath: host!.result!.path,
        apiPath: settings.SlaDefinitions.create.path!,
        userId: performingUser.userAccountId,
        formData: obj,
        accessToken: accessToken,
      });
    }
    return updateAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.SlaDefinitions.update.path!}/${id}`,
      userId: performingUser.userAccountId,
      formData: obj,
      accessToken: accessToken,
    });
  };

  export const getAsync = async (
    performingUser: IUserClaims,
    accessToken: string,
    id: string
  ): Promise<FunctionalResult<SlaDefinition_Types.slaDefinitionItem>> => {
    const host = await getHostConfigAsync();
    if (host.isFailure) {
      return FunctionalResult.Error('Failed to load host configuration');
    }

    return fetchAction({
      hostPath: host!.result!.path,
      apiPath: `${settings.SlaDefinitions.get.path}/${id}`,
      userId: performingUser.userAccountId,
      accessToken: accessToken,
    });
  };

  /**
   * Creates an empty SLA definition object for the UX to bind to.
   * @returns A default SLA definition object.
   */
  export const createDefault = (billingId:string): SlaDefinition_Types.slaDefinitionItem => {
    return {
      name: '',
      description: '',
      billingDetailsId: billingId,
      serviceDefinitionId: undefined,
      duration: undefined,
      startStatus: undefined,
      endStatus: undefined,
      startServiceStatus: undefined,
      endServiceStatus: undefined,
    };
  };
}
export default SlaDefinitionActions;
