import React from 'react';
import { useIntl } from 'react-intl';
import actions from '../../../systemUtils/pricing/PricingActions';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useMsal } from '@azure/msal-react';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import CommonTableTypes from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import CommonTable from '../../../systemComponents/sharedControls/tables/commonTable/CommonTable';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
const PricingDefinition_Index: React.FC = () => {
  const intl = useIntl();
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const context = React.useContext(CrumbUpdateContext);
  const { userClaims } = React.useContext(UserClaimsContext);
const { userSettings } = useUserSettingsContext();
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [errorProps, setErrorProps] = React.useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });
  const [pricings, setPricings] = React.useState<CommonTableTypes.DataRow[]>(
    []
  );

  const setErrorMessages = (message: string, ent?: () => void) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      bodyText: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  React.useEffect(() => {
    if (!UserCore.userIsSuperUser(userClaims!.user!)) {
      navigate('/');
      return;
    }
    context?.onEvent(locales.breadcrumbs);
    actions
      .findActivePricingDefinitionsAsync(
        null,
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!
      )
      .then((result) => {
        if (result.isFailure || !result.result) {
          setErrorMessages(locales.errorDetails.loadingFailed);
          return;
        }

        setPricings(
          result.result.map((pricing, index) => {
            return {
              id: pricing.id,
              data: [pricing.name, pricing.description, pricing.currency],
              buttonColumn: (
                <ButtonBox
                  key={`pricing-${pricing.id}-${index}-button-box`}
                  id={`pricing-${pricing.id}-${index}-button-box`}
                  displayBorder={false}
                  buttons={[
                    {
                      id: `pricing-${pricing.id}-${index}-edit`,
                      controlState: 'positive',
                      label: locales.editButton,
                      onClick: () => navigate(`/pricing/edit/${pricing.id}`),
                    }
                  ]}
                />
              ),
            };
          })
        );
        setIsLoading(false);
      });
  }, []);

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : !errorProps.show ? (
    <div className="Main-Form-Layout">
      <CommonTable
        pagingDetails={{
          currentPageSize: userSettings.startingPageSize,
          pageSizes: userSettings.pageSizes,
        }}
        headers={locales.colNames}
        rows={pricings}
        labels={{
          tableName: locales.tableName,
          of: locales.of,
          size: locales.size,
          emptyMessage: locales.emptyMessage,
        }}
        key="fk"
      />
      <div className="Button-Container">
        <InformationButton
          buttonDetails={{
            itemKey: 'infoButtonBilling',
            label: locales.billingButton,
            mode: 'positive',
            clickEvent: () => navigate('/pricing/create'),
          }}
        >
          {locales.billingSummary}
        </InformationButton>
      </div>
    </div>
  ) : (
    <CommonModal
      headerText={errorProps.headerText}
      bodyText={errorProps.bodyText}
      buttonDetails={errorProps.buttonDetails}
      show={errorProps.show}
    />
  );
};

export default PricingDefinition_Index;
