import React, { useEffect, useState, useContext, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import CaseUtils from '../../../systemUtils/case/caseUtils';
import {
  CaseDetail_Types,
  Case_Types,
} from '../../../sysObjects/apiModels/Case.types';
import Enumerations, {
  LocalEnumerations,
  getHeaders,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import { LogLevel, SystemLogger } from '../../../systemUtils/Logger';
import OrgActions from '../../../systemUtils/organisation/OrganisationActions';
import extUserActions from '../../../systemUtils/userUtils/ExternalUserActions';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import {
  FormControlOnChangeData,
  FormControlRef,
} from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState, KeyValuePair } from '../../../sysObjects/common.types';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import FormDropDown from '../../../systemComponents/sharedControls/formControls/formDropDown/FormDropDown';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import FormCheckboxList from '../../../systemComponents/sharedControls/formControls/formCheckboxList/FormCheckboxList';
import ListTable from '../../../systemComponents/sharedControls/tables/listTable/ListTable';
import ButtonBox from '../../../systemComponents/sharedControls/reusableBlocks/buttonBox/ButtonBox';
import FormTextArea from '../../../systemComponents/sharedControls/formControls/formTextArea/FormTextArea';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import Expander from '../../../systemComponents/sharedControls/general/expander/Expander';
import { useUserSettingsContext } from '../../../systemComponents/sharedControls/contexts/UserSettingsContextType';

const CreateReferralPage: React.FC<CreateReferralPageProps> = ({}) => {
  const intl = useIntl();
  const { userClaims } = useContext(UserClaimsContext);
  const locales = require(`./locales/${intl.locale}.json`);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const context = React.useContext(CrumbUpdateContext);
  const { userSettings } = useUserSettingsContext();
  var formRefs = [
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
    [
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
      useRef<FormControlRef>(null),
    ],
  ];
  var formContactRefs = [
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
  ];
  const [hideFormatOther, setHideFormatOther] = useState<boolean>(true);
  const [hideReferralOther, setHideReferralOther] = useState<boolean>(true);
  const [note, setNote] = useState<string>('');
  const [expandAddNote, setExpandAddNote] = useState<boolean>(false);
  const [notes, setNotes] = useState<noteObj[]>([]);
  const [pageStates, setPages] = useState<KeyValuePair<string>[]>([
    {
      key: locales.tabNames.pageOne,
      value: 'neutral',
    },
    {
      key: locales.tabNames.pageTwo,
      value: 'neutral',
    },
    {
      key: locales.tabNames.pageThree,
      value: 'neutral',
    },
    {
      key: locales.tabNames.pageFour,
      value: 'neutral',
    },
  ]);
  const [formData, setFormData] = useState<Case_Types.BaseCase>(
    CaseUtils.createDefaultReferralCase(),
  );
  const [idkSelectionStates, setIdkSelectionStates] = useState<
    Array<KeyValuePair<string>>
  >([]);

  const [conditions, setConditions] = useState<KeyValuePair<number>[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [formats, setFormats] = useState<KeyValuePair<number>[]>([]);
  const [referralReasons, setReferralReasons] = useState<
    KeyValuePair<number>[]
  >([]);

  const [servicesRequired, setServicesRequired] = useState<
    KeyValuePair<number>[]
  >([]);

  const [customers, setCustomers] = useState<KeyValuePair<string>[]>([]);
  const [referralOrganisations, setReferralOrganisations] = useState<
    KeyValuePair<string>[]
  >([]);
  const [referralAccount, setReferralAccount] = useState<
    KeyValuePair<string>[]
  >([]);
  const [billingOrgs, setBillingOrgs] = useState<KeyValuePair<string>[]>([]);

  const [caseContact, setCaseContact] = useState<CaseDetail_Types.CaseContact>({
    name: '',
    emailAddress: '',
    relationshipToCustomer: '',
    telephoneNumber: '',
  });

  const [addContactExpanded, setAddContactExpanded] = useState<boolean>(false);
  const [contacts, setContacts] = useState<contactObj[]>([]);

  const [errorProps, setErrorProps] = useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });

  const setError = (
    message: string | React.ReactNode,
    errorString?: string,
    ent?: () => void,
  ) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      errorString: errorString,
      children: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  useEffect(() => {
    if (!UserCore.userIsCaseManagerOrHigher(userClaims!.user!)) {
      setError(locales.errorDetails.permissionError);
      return;
    }

    setConditions(Enumerations.getSuspectedConditions(intl.locale));
    setFormats(Enumerations.getAppointmentFormats(intl.locale));
    setReferralReasons(Enumerations.getReferralReasons(intl.locale));
    setServicesRequired(Enumerations.getServiceRequired(intl.locale));
    setIsLoading(false);

    OrgActions.lookUpAsync(
      { hasBillingDetails: true },
      userClaims?.user!,
      instance.getActiveAccount()!.idToken!,
    ).then((rst) => {
      if (rst.error) {
        setError(
          locales.errorDetails.loadingFailed,
          `${locales.errorDetails.code}${rst.error}${
            rst.errorCode ? ` - ${rst.errorCode}` : ''
          }`,
        );

        return;
      }
      setReferralOrganisations(
        rst.result!.map((item) => {
          return { key: item.id, value: item.name };
        }),
      );
    });
    context?.onEvent(locales.breadcrumbs);
  }, []);

  const handleFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleFormChangeWithIdkOption = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    let newValue = result.value as number[] | null;
    let fieldId = result.fieldId as string;

    let selectionArray = idkSelectionStates as KeyValuePair<string>[];

    if (newValue?.includes(100)) {
      if (selectionArray.find((x) => x.key === fieldId)?.value === 'true') {
        newValue = newValue.filter((value) => value !== 100);
      } else {
        newValue = [100];
      }
    }

    setFormData((prevData) => {
      return {
        ...prevData,
        [result.fieldId]: newValue,
      };
    });

    setIdkSelectionStates((previousStateData: KeyValuePair<string>[]) => {
      const index = previousStateData.findIndex((item) => item.key === fieldId);

      if (index !== -1) {
        return previousStateData.map((item, i) => {
          if (i === index) {
            return {
              ...item,
              value: newValue?.includes(100) ? 'true' : 'false',
            };
          }
          return item;
        });
      } else {
        return [
          ...previousStateData,
          { key: fieldId, value: newValue?.includes(100) ? 'true' : 'false' },
        ];
      }
    });
  };

  const handleOrgChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      caseIdentifiers: {
        referrerOrganisationId: result.value as string,
        customerAccountId: '',
        defaultBillingOrganisationId: '',
        referrerAccountId: '',
      },
    }));

    setReferralAccount([]);
    setCustomers([]);
    setBillingOrgs([]);

    if (result.value !== '') {
      extUserActions
        .customerLookUpAsync(
          result.value as string,
          userClaims?.user!,
          instance.getActiveAccount()!.idToken!,
        )
        .then((rst) => {
          if (rst.error) {
            setError(
              locales.errorDetails.loadingFailed,
              `${locales.errorDetails.code}${rst.error}${
                rst.errorCode ? ` - ${rst.errorCode}` : ''
              }`,
            );

            return;
          }
          setCustomers(
            rst.result!.map((item) => {
              return { key: item.id, value: `${item.surname}, ${item.name}` };
            }),
          );
        });

      OrgActions.findRelatedOrgsAsync(
        result.value as string,
        userClaims?.user!,
        instance.getActiveAccount()!.idToken!,
      ).then((rst) => {
        if (rst.error) {
          setError(
            locales.errorDetails.loadingFailed,
            `${locales.errorDetails.code}${rst.error}${
              rst.errorCode ? ` - ${rst.errorCode}` : ''
            }`,
          );
          return;
        }
        setBillingOrgs(
          rst
            .result!.filter((item) => item.hasBillingDetails)
            .map((item) => {
              return { key: item.id, value: item.name };
            }),
        );
      });
    }

    extUserActions
      .findUsersAsync(getHeaders(userClaims, instance), {
        role: LocalEnumerations.Roles.Referrer,
        orgId: result.value as string,
        status: [
          LocalEnumerations.UserStatuses.Active,
          LocalEnumerations.UserStatuses.Invited,
        ],
      })
      .then((rst) => {
        if (rst.error) {
          setError(
            locales.errorDetails.loadingFailed,
            `${locales.errorDetails.code}${rst.error}${
              rst.errorCode ? ` - ${rst.errorCode}` : ''
            }`,
          );

          return;
        }
        setReferralAccount(
          rst.result!.map((item) => {
            return { key: item.id, value: `${item.surname}, ${item.name}` };
          }),
        );
      });
  };

  const handleCaseChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setFormData((prevData) => ({
      ...prevData,
      caseIdentifiers: {
        ...prevData.caseIdentifiers,
        [result.fieldId]: result.value,
      },
    }));
  };

  const handleContactFormChange = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    setCaseContact((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));
  };

  const handleAppointmentChanged = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    const value =
      typeof result.value === 'string'
        ? parseInt(result.value, 10)
        : result.value;

    if (result.fieldId === 'appointmentFormat') {
      const test = value !== 99;
      setHideFormatOther(test);
      if (test) {
        setFormData((prevData) => ({
          ...prevData,
          appointmentFormatOther: '',
        }));
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: value,
    }));
  };

  const handleReferralChanged = (
    result: FormControlOnChangeData<
      string | number | number[] | string[] | null
    >,
  ) => {
    const value =
      typeof result.value === 'string'
        ? parseInt(result.value, 10)
        : result.value;

    if (result.fieldId === 'referralReason') {
      const test = value !== 99;
      setHideReferralOther(test);
      if (test) {
        setFormData((prevData) => ({
          ...prevData,
          referralReasonOther: '',
        }));
      }
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: value,
    }));
  };

  const saveRecord = () => {
    let allValid: boolean[] = [];

    formRefs.forEach((ref, i) => {
      const validationResults = ref.map((innerRef) =>
        innerRef.current?.triggerValidation(),
      );
      const tabValid = validationResults.every((result) => result === true);
      allValid.push(tabValid);
      setPages((prevPages) => {
        const updatedPages = [...prevPages];
        updatedPages[i] = {
          ...updatedPages[i],
          value: tabValid ? 'positive' : 'negative',
        };
        return updatedPages;
      });
    });

    const pageValid = allValid.every((isValid) => isValid);
    if (pageValid) {
      setFormData((prevData) => ({
        ...prevData,
        notes: notes.map((n) => n.note),
        caseContacts: contacts.map((c) => {
          return {
            name: c.name,
            emailAddress: c.emailAddress,
            relationshipToCustomer: c.relationshipToCustomer,
            telephoneNumber: c.telephoneNumber,
          };
        }),
      }));
      CaseUtils.saveReferralCaseAsync(
        userClaims!.user!,
        instance.getActiveAccount()!.idToken!,
        formData,
      ).then((result) => {
        if (result.isFailure) {
          setError(
            getServerErrors(locales, result.errorCode),
            result.error,
            () => {
              setErrorProps((prevData) => ({
                ...prevData,
                show: false,
              }));
            },
          );
          return;
        }
        navigate('/Cases');
      });
      return;
    }
  };

  const pageChange = (oldPage: number) => {
    if (oldPage > 1) {
      return;
    }
    const validationResults = formRefs[oldPage].map((ref) =>
      ref.current?.triggerValidation(),
    );
    const allValid = validationResults.every((result) => result === true);

    setPages((prevPages) => {
      const updatedPages = [...prevPages];
      updatedPages[oldPage] = {
        ...updatedPages[oldPage],
        value: allValid ? 'positive' : 'negative',
      };
      return updatedPages;
    });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : errorProps.show ? (
    <div>
      <CommonModal
        headerText={errorProps.headerText}
        buttonDetails={errorProps.buttonDetails}
        show={errorProps.show}
      >
        {errorProps.children || errorProps.bodyText}
      </CommonModal>
    </div>
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        backLabel={locales.labels.back}
        nextLabel={locales.labels.forward}
        pageChangeAction={pageChange}
        pages={[
          {
            name: pageStates[0].key,
            mode: pageStates[0].value as ControlState,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormDropDown
                  displayMode="Box"
                  fieldId="referrerOrganisationId"
                  id="referrerOrganisationId"
                  label={locales.controls.referralOrg}
                  items={referralOrganisations}
                  value={formData.caseIdentifiers.referrerOrganisationId}
                  defaultText="Select"
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.referralOrg} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0][0]}
                  onChange={handleOrgChange}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="customerAccountId"
                  id="customerAccountId"
                  label={locales.controls.customerAccountId}
                  items={customers}
                  value={formData.caseIdentifiers.customerAccountId}
                  defaultText="Select"
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.customerAccountId} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0][1]}
                  onChange={handleCaseChange}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="referrerAccountId"
                  id="referrerAccountId"
                  label={locales.controls.referrerAccountId}
                  items={referralAccount}
                  value={formData.caseIdentifiers.referrerAccountId}
                  defaultText="Select"
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.referrerAccountId} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0][2]}
                  onChange={handleCaseChange}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="defaultBillingOrganisationId"
                  id="defaultBillingOrganisationId"
                  label={locales.controls.defaultBillingOrganisationId}
                  items={billingOrgs}
                  value={formData.caseIdentifiers.defaultBillingOrganisationId}
                  defaultText="Select"
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.defaultBillingOrganisationId} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[0][3]}
                  onChange={handleCaseChange}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="referrerCaseReference"
                  id="referrerCaseReference"
                  label={locales.controls.referrerCaseReference}
                  textInputType="text"
                  onChange={handleFormChange}
                  ref={formRefs[0][4]}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.referrerCaseReference} ${locales.common.requiredMessage}`,
                  }}
                  value={formData.referrerCaseReference}
                />
              </>
            ),
          },
          {
            name: pageStates[1].key,
            mode: pageStates[1].value as ControlState,
            enabled: true,
            orderNo: 2,
            showAsOrdered: true,
            content: (
              <>
                <FormCheckboxList
                  displayMode="Box"
                  fieldId="suspectedConditions"
                  id="suspectedConditions"
                  label={locales.controls.suspectedConditions}
                  allowManySelect={true}
                  items={conditions}
                  value={formData.suspectedConditions}
                  onChange={handleFormChangeWithIdkOption}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.suspectedConditions} ${locales.common.requiredMessage}`,
                  }}
                  ref={formRefs[1][0]}
                />
                <FormCheckboxList
                  displayMode="Box"
                  fieldId="servicesRequired"
                  id="servicesRequired"
                  label={locales.controls.servicesRequired}
                  allowManySelect={true}
                  items={servicesRequired}
                  value={formData.servicesRequired}
                  onChange={handleFormChangeWithIdkOption}
                  ref={formRefs[1][1]}
                  requiredDetails={{
                    formLabel: locales.common.required,
                    message: `${locales.controls.servicesRequired} ${locales.common.requiredMessage}`,
                  }}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="appointmentFormat"
                  id="appointmentFormat"
                  label={locales.controls.appointmentFormat}
                  items={formats}
                  value={formData.appointmentFormat}
                  defaultText="Select"
                  onChange={handleAppointmentChanged}
                  ref={formRefs[1][2]}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="appointmentFormatOther"
                  id="appointmentFormatOther"
                  label={locales.controls.appointmentFormatOther}
                  textInputType="text"
                  hidden={hideFormatOther}
                  onChange={handleFormChange}
                  value={formData.appointmentFormatOther}
                  ref={formRefs[1][3]}
                />
                <FormDropDown
                  displayMode="Box"
                  fieldId="referralReason"
                  id="referralReason"
                  label={locales.controls.referralReason}
                  items={referralReasons}
                  value={formData.referralReason}
                  defaultText="Select"
                  onChange={handleReferralChanged}
                  ref={formRefs[1][4]}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="referralReasonOther"
                  id="referralReasonOther"
                  label={locales.controls.referralReasonOther}
                  textInputType="text"
                  hidden={hideReferralOther}
                  onChange={handleFormChange}
                  value={formData.referralReasonOther}
                  ref={formRefs[1][5]}
                />
              </>
            ),
          },
          {
            name: pageStates[2].key,
            mode: pageStates[2].value as ControlState,
            enabled: true,
            orderNo: 3,
            showAsOrdered: true,
            content: (
              <ListTable
              isLoading={false}
                tableClassName="Create-Referral-Notes"
                headers={[
                  'Header-Check',
                  ...locales.controls.addNote.table.headers,
                ].map((header, i) => {
                  if (i === 0) {
                    return (
                      <div className="Header-Check" key={`header_${i}`}></div>
                    );
                  }
                  return (
                    <div className="Heading" key={`header_${i}`}>
                      {header}
                    </div>
                  );
                })}
                labels={{
                  of: locales.controls.addNote.table.of,
                  size: locales.controls.addNote.table.size,
                  tableName: locales.controls.addNote.table.tableName,
                  emptyMessage: locales.controls.addNote.table.emptyMessage,
                }}
                pagingDetails={{
                  pageSizes: userSettings.pageSizes,
                  currentPageSize: userSettings.startingPageSize,
                }}
                rows={notes.map((item, i) => (
                  <div key={`note_${i}`} className="Note-Details">
                    <Expander
                      isExpanded={item.isExpanded}
                      id={`note_${i}_Expander`}
                      key={`note_${i}_Expander`}
                      eventHandler={{
                        onClick: () => {
                          const updatedNotes = notes.map((n) => {
                            if (n.id === item.id) {
                              return {
                                ...n,
                                isExpanded: !n.isExpanded,
                              };
                            }
                            return n;
                          });
                          setNotes(updatedNotes);
                        },
                      }}
                    />
                    <span
                      className={`Note-${
                        item.isExpanded ? 'Expanded' : 'Collapsed'
                      }`}
                    >
                      {item.note}
                    </span>
                    {item.isExpanded && (
                      <ButtonBox
                        id={`notes_${item.id}`}
                        displayBorder={true}
                        buttons={[
                          {
                            id: `notes_${item.id}-${i}`,
                            controlState: 'negative',
                            label: locales.controls.removeNote.button,
                            onClick: () => {
                              const updatedNotes = notes.filter(
                                (n) => n.id !== item.id,
                              );
                              setNotes(updatedNotes);
                            },
                          },
                        ]}
                      />
                    )}
                  </div>
                ))}
                defaultItem={
                  <div className="Add-Note">
                    <Expander
                      id={`$Add_Row_Status`}
                      key={`$Add_Row_Status`}
                      isExpanded={expandAddNote}
                      eventHandler={{
                        onClick: () => setExpandAddNote(!expandAddNote),
                      }}
                    />
                    {!expandAddNote && (
                      <span>{locales.controls.noteTable.addNote}</span>
                    )}
                    {expandAddNote && (
                      <>
                        <FormTextArea
                          id="noteText"
                          label={locales.controls.noteTable.addNote}
                          fieldId="moteText"
                          value={note}
                          displayMode="Column"
                          helpMessage={locales.controls.addNote.help}
                          onChange={(e) => {
                            setNote(e.value as string);
                          }}
                        />
                        <ButtonBox
                          id="note_Add"
                          key="noteAdd"
                          buttons={[
                            {
                              label: locales.controls.addNote.button,
                              controlState: 'positive',
                              id: 'note_Add',
                              onClick: () => {
                                if (note.trim() === '') {
                                  return;
                                }
                                const updatedNotes = [
                                  ...notes,
                                  {
                                    id: new Date().getTime().toString(),
                                    note: note,
                                    isExpanded: false,
                                    isEditing: false,
                                  },
                                ];
                                setNotes(updatedNotes);
                                setNote('');
                                setExpandAddNote(false);
                              },
                            },
                          ]}
                          displayBorder={true}
                        />
                      </>
                    )}
                  </div>
                }
              />
            ),
          },
          {
            name: pageStates[3].key,
            mode: pageStates[3].value as ControlState,
            enabled: true,
            orderNo: 4,
            showAsOrdered: true,
            content: (
              <ListTable
                isLoading={false}
                headers={[
                  'Header-Check',
                  ...locales.controls.addContact.table.headers,
                ].map((header, i) => {
                  if (i === 0) {
                    return (
                      <div className="Header-Check" key={`header_${i}`}></div>
                    );
                  }
                  return (
                    <div className="Heading" key={`header_${i}`}>
                      {header}
                    </div>
                  );
                })}
                labels={{
                  of: locales.controls.addContact.table.of,
                  size: locales.controls.addContact.table.size,
                  tableName: locales.controls.addContact.table.tableName,
                  emptyMessage: locales.controls.addContact.table.emptyMessage,
                }}
                pagingDetails={{
                  pageSizes: userSettings.pageSizes,
                  currentPageSize: userSettings.startingPageSize,
                }}
                rows={[
                  contacts.map((item, i) => (
                    <div key={`contact_${i}`} className="Contact-Details">
                      <Expander
                        key={`contact_${i}_Expander`}
                        id={`contact_${i}_Expander`}
                        isExpanded={item.isExpanded}
                        eventHandler={{
                          onClick: () => {
                            const updatedContacts = contacts.map((n) => {
                              if (n.id === item.id) {
                                return {
                                  ...n,
                                  isExpanded: !n.isExpanded,
                                };
                              }
                              return n;
                            });
                            setContacts(updatedContacts);
                          },
                        }}
                      />
                      {!item.isExpanded && (
                        <div>
                          <span
                            className={`Note-${
                              item.isExpanded ? 'Expanded' : 'Collapsed'
                            }`}
                          >
                            {item.name}
                          </span>
                        </div>
                      )}
                      {item.isExpanded && (
                        <div className="Contact-Information">
                          <div className="col">
                            <span>{locales.controls.addContact.name}</span>
                          </div>
                          <div className="value-col">
                            <span>{item.name}</span>
                          </div>
                          <div className="col">
                            <span>
                              {locales.controls.addContact.relationship}
                            </span>
                          </div>
                          <div className="value-col">
                            <span>{item.relationshipToCustomer}</span>
                          </div>
                          <div className="col">
                            <span>
                              {locales.controls.addContact.emailAddress}
                            </span>
                          </div>
                          <div className="value-col">
                            <span>{item.emailAddress}</span>
                          </div>
                          <div className="col">
                            <span>{locales.controls.addContact.tel}</span>
                          </div>
                          <div className="value-col">
                            <span>{item.telephoneNumber}</span>
                          </div>
                          <ButtonBox
                            id="Remove-Contact"
                            displayBorder={true}
                            buttons={[
                              {
                                id: `Remove_contact_${i}`,
                                controlState: 'negative',
                                label: locales.controls.removeContact.button,
                                onClick: () => {
                                  const updatedContacts = contacts.filter(
                                    (n) => n.id !== item.id,
                                  );
                                  setContacts(updatedContacts);
                                },
                              },
                            ]}
                          />
                        </div>
                      )}
                    </div>
                  )),
                ]}
                tableClassName="Create-Referral-Contacts"
                defaultItem={
                  <div className="Add-Contact">
                    <Expander
                      id={`Add_Contact-Row_Status`}
                      key={`Add_Contact-Row_Status`}
                      isExpanded={addContactExpanded}
                      eventHandler={{
                        onClick: () =>
                          setAddContactExpanded(!addContactExpanded),
                      }}
                    />
                    {!addContactExpanded && (
                      <span>{locales.controls.addContact.button}</span>
                    )}
                    {addContactExpanded && (
                      <div className="Add-Contact-Expanded">
                        <FormTextCapture
                          id="name"
                          label={locales.controls.addContact.name}
                          fieldId="name"
                          value={caseContact.name}
                          textInputType="text"
                          displayMode="Column"
                          helpMessage={locales.controls.addContact.help}
                          onChange={handleContactFormChange}
                          ref={formContactRefs[0]}
                        />
                        <FormTextCapture
                          id="relationshipToCustomer"
                          label={locales.controls.addContact.relationship}
                          fieldId="relationshipToCustomer"
                          value={caseContact.relationshipToCustomer}
                          textInputType="text"
                          displayMode="Column"
                          onChange={handleContactFormChange}
                          ref={formContactRefs[1]}
                        />
                        <FormTextCapture
                          id="emailAddress"
                          label={locales.controls.addContact.emailAddress}
                          fieldId="emailAddress"
                          value={caseContact.emailAddress}
                          textInputType="email"
                          displayMode="Column"
                          onChange={handleContactFormChange}
                          ref={formContactRefs[2]}
                        />
                        <FormTextCapture
                          id="telephoneNumber"
                          label={locales.controls.addContact.tel}
                          fieldId="telephoneNumber"
                          value={caseContact.telephoneNumber}
                          textInputType="tel"
                          displayMode="Column"
                          onChange={handleContactFormChange}
                          ref={formContactRefs[3]}
                        />
                        <ButtonBox
                          id="Add_Contact"
                          displayBorder={true}
                          key={`addContact`}
                          buttons={[
                            {
                              id: `Add_Contact`,
                              controlState: 'positive',
                              label: locales.controls.addContact.button,
                              onClick: () => {
                                const validationResults = formContactRefs.map(
                                  (ref) => ref.current?.triggerValidation(),
                                );
                                const result = validationResults.every(
                                  (r) => r,
                                );
                                SystemLogger(LogLevel.Info, validationResults);
                                if (!result) {
                                  return;
                                }
                                setContacts([
                                  ...contacts,
                                  {
                                    name: caseContact.name,
                                    relationshipToCustomer:
                                      caseContact.relationshipToCustomer,
                                    emailAddress: caseContact.emailAddress,
                                    telephoneNumber:
                                      caseContact.telephoneNumber,
                                    id: new Date().getTime().toString(),
                                    isExpanded: false,
                                  },
                                ]);
                                setCaseContact({
                                  name: '',
                                  emailAddress: '',
                                  relationshipToCustomer: '',
                                  telephoneNumber: '',
                                });
                                formContactRefs.forEach((r) => {
                                  r.current?.reset();
                                });
                              },
                            },
                          ]}
                        />
                      </div>
                    )}
                  </div>
                }
              />
            ),
          },
        ]}
      />
      <InformationButton
        key="informationSAve"
        buttonDetails={{
          itemKey: 'informationSave',
          clickEvent: saveRecord,
          label: locales.labels.save,
          mode: 'positive',
        }}
      >
        <>This saves the user record</>
      </InformationButton>
    </div>
  );
};
export default CreateReferralPage;

export interface CreateReferralPageProps {}

interface noteObj {
  id: string;
  note: string;
  isExpanded: boolean;
}

interface contactObj extends CaseDetail_Types.CaseContact {
  id: string;
  isExpanded: boolean;
}
