import React, { useContext } from 'react';
import BillableItemsTabTypes from './BillableItemsTab.types';
import BillableItemRowTypes from '../../billableItems/billableItemRow/BillableItemRow.types';
import './BillableItemsTab.css';
import { useMsal } from '@azure/msal-react';
import BillableItemRow from '../../billableItems/billableItemRow/BillableItemRow';
import AddBillableItemRow from '../../billableItems/addBillableItemRow/AddBillableItemRow';
import { UserClaimsContext } from '../../../../sharedControls/contexts/UserClaimsContext';
import CaseUtils from '../../../../../systemUtils/case/caseUtils';
import { getServerErrors, getHeaders } from '../../../../../systemUtils/common/CommonHelpers';
import ListTable from '../../../../sharedControls/tables/listTable/ListTable';
import BillableItem_Types  from '../../../../../sysObjects/apiModels/BillableItem.types';
import BillableItemActions from '../../../../../utils/BillableItemsUtils/BillableItemsActions';

const BillableItemsTab: React.FC<BillableItemsTabTypes.Props> = (props) => {
  const [rows, setRows] = React.useState<
    BillableItemRowTypes.BillableItemRowItem[]
  >([]);

  const { userClaims } = useContext(UserClaimsContext);
  const { instance } = useMsal();
  const locales = require(`./locales/${props.intl.locale}.json`);

  React.useEffect(() => {
    setRows(props.data);
  }, [props.data]);

  const setError = (message: string | React.ReactNode, ent?: () => void) => {
    props.eventHandlers.onError(message);
  };

  const handleAddBillableItem = async (serviceToFulfillId: string, note: string | null) => {
    await CaseUtils.addManualBillableItemToCaseAsync(
      {
        serviceToFulfillId: serviceToFulfillId,
        note: note,
      },
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!)
      .then((result) => {
        if (result.isFailure) {
          setError(getServerErrors(props.labels, result.errorCode));
          return;
        }

        props.setRefreshRequired();
      })
      .catch(() => {
        setError(props.labels.errorDetails.saveFailed);
      });
  }

  return (
    <>
      <ListTable
                isLoading={false}
        tableClassName='BillableItems-Table'
        labels={{
          tableName: props.labels.table.tableName,
          of: props.labels.table.of,
          size: props.labels.table.size,
          emptyMessage: props.labels.table.emptyMessage,
        }}
        headers={[
          <div key="StatusCol"></div>,
          ...props.labels.table.tableHeaders.map((header, hI) => {
            return <div key={`BillItems_Header_${hI}`}>{header}</div>;
          }),
        ]}
        rows={
          rows?.map((row, rI) => {
            return <BillableItemRow key={`BillItems_Rows_${rI}`}
              item={row}
              intl={props.intl}
              states={props.labels.billableStates}
              labels={props.labels.rowLabels}
              eventHandlers = {{
                raiseInvoice : (caseId: string, billableIds: string[]) => {
                  BillableItemActions.createInvoiceAsync(
                        getHeaders(userClaims, instance),
                        caseId,
                        billableIds
                      ).then((result) => {
                        if (result.isFailure) {
                          setError(getServerErrors(props.labels.errorDetails.serverErrors, result.errorCode));
                          return;
                        }
                        else{
                          props.setRefreshRequired();
                        }
                      });
                }, 
                editBillableItem : (billableItem: BillableItemRowTypes.BillableItemRowItem) => 
                {
                  billableItem.isEditing = true;
                  setRows([...rows]);
                }, 
                updateBillableItem : (modifiedBillableItemPrice: BillableItem_Types.ModifiedBillableItemPriceType) => {
                  BillableItemActions.modifyBillableItemAsync(
                        getHeaders(userClaims, instance),
                        modifiedBillableItemPrice
                       )
                       .then((result) =>  {
                        if (result.isFailure) {
                          setError(locales.errorDetails.errors.modifyBillableItemError);
                          return;
                        }
                        else
                        {
                          props.setRefreshRequired();
                        }
                      });
                }, 
                cancelEdit : (billableItem: BillableItemRowTypes.BillableItemRowItem) => {
                  billableItem.isEditing = false;
                  setRows([...rows]);
                },
                onError : (message: string | React.ReactNode) => {}
              }}
            />;
          }) || []
        }
           
        pagingDetails={props.pagingDetails}
        defaultItem={
          props.canAddBillableItems && props.servicesToFulfill.length > 0 && (
            <AddBillableItemRow
              labels={props.labels.addRowLabels}
              servicesToFulfill={props.servicesToFulfill}
              onSave={handleAddBillableItem}
            />
          )
        }
      />
    </>
  );


};

export default BillableItemsTab;
