import React from 'react';
import { StatusBlockProps } from './StatusBlock.types';

import './StatusBlock.css';
import Uneditable from '../../svg/Uneditable';
import Good from '../../svg/Good';
import Bad from '../../svg/Bad';
import None from '../../svg/None';
import Warning from '../../svg/Warning';
import ListItem from '../../svg/ListItem';
import BaseBlock from '../baseBlock/BaseBlock';


const StatusBlock: React.FC<StatusBlockProps> = (props) => {
  const readOnly = <Uneditable className="state-icon readOnly" />;
  const iconMappings = {
    positive: <Good className="state-icon" />,
    negative: <Bad className="state-icon" />,
    neutral: <None className="state-icon" />,
    warning: <Warning className="state-icon" />,
    locked: <ListItem className="state-icon" />,
  };

  const icon = iconMappings[props.boxState] || null;

  return (
    <BaseBlock

      onClick={props.onclick}
      id={props.id}
      testid={`state-box-${props.id}`}
      key={`container_checkbox_${props.id}`}
      aria-hidden="true"
      boxSize={props.boxSize}
      rounded={true}
      aria-label="State Description"
      className={`state-box Impact-Strong ${props.boxState} ${
        !props.allowHover ? '' : 'allowHover'
      } light ${props.boxSize} ${
        props.showIcon === true ? '' : 'lexxic-text'
      } ${!props.className ? '' : props.className}`}
      content={
        props.showIcon === true
          ? props.readonly === true
            ? readOnly
            : icon
          : props.content
      }
    />
  );
};

export default StatusBlock;
