import React, { useContext, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import UserCore from '../../../systemUtils/userUtils/SystemUserActions';
import { useIntl } from 'react-intl';
import { CaseDetail_Types } from '../../../sysObjects/apiModels/Case.types';
import CaseUtils from '../../../systemUtils/case/caseUtils';
import {
  LocalEnumerations,
  getDate,
  getServerErrors,
} from '../../../systemUtils/common/CommonHelpers';
import { useMsal } from '@azure/msal-react';
import { UserClaimsContext } from '../../../systemComponents/sharedControls/contexts/UserClaimsContext';
import { FormControlOnChangeData, FormControlRef } from '../../../systemComponents/sharedControls/formControls/formControlContainer/FormControlContainer.types';
import { ControlState } from '../../../sysObjects/common.types';
import CrumbUpdateContext from '../../../systemComponents/sharedControls/contexts/CrumbUpdateContext';
import FormCheckbox from '../../../systemComponents/sharedControls/formControls/formCheckbox/FormCheckbox';
import FormDate from '../../../systemComponents/sharedControls/formControls/formDate/FormDate';
import FormTextCapture from '../../../systemComponents/sharedControls/formControls/formTextCapture/FormTextCapture';
import InformationButton from '../../../systemComponents/sharedControls/general/InformationButton/InformationButton';
import PageLoader from '../../../systemComponents/sharedControls/general/loading/pageLoader/PageLoader';
import PillControl from '../../../systemComponents/sharedControls/formControls/pillControl/PillControl';
import CommonModalTypes from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal.types';
import CommonModal from '../../../systemComponents/sharedControls/pageLevel/commonModal/CommonModal';
import './CreateTriagePage.css';

const CreateTriagePage: React.FC = () => {
  const { caseId } = useParams();
  const intl = useIntl();
  const { userClaims } = useContext(UserClaimsContext);
  const navigate = useNavigate();
  const { instance } = useMsal();
  const locales = require(`./locales/${intl.locale}.json`);
  const context = React.useContext(CrumbUpdateContext);

  const [formData, setFormData] = useState<CaseDetail_Types.TriageDetail>(
    CaseUtils.createEmptyTriage(caseId || '', getDate())
  );

  const pageRefs = [
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
    useRef<FormControlRef>(null),
  ];
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabState, setTabState] = useState<ControlState>('neutral');
  const [isAwaitingPrerequisites, setIsAwaitingPrerequisites] =
    useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);

  const [errorProps, setErrorProps] = useState<CommonModalTypes.Props>({
    bodyText: '',
    buttonDetails: {},
    headerText: '',
    show: false,
  });

  const getDateOrNull = (
    dateString: string | null | undefined | Date
  ): Date | null => {
    return dateString ? new Date(dateString) : null;
  };

  const setError = (
    message: string | React.ReactNode,
    errorString?: string,
    ent?: () => void
  ) => {
    const event: () => void = !ent
      ? () => {
          navigate('/');
        }
      : (ent as () => void);
    setIsLoading(false);
    setErrorProps({
      headerText: locales.errorDetails.heading,
      errorString: errorString,
      children: message,
      buttonDetails: {
        label: locales.errorDetails.button,
        clickEvent: event,
      },
      show: true,
    });
  };

  useEffect(() => {
    UserCore.isInRoleAsync(userClaims!.user!, [
      LocalEnumerations.Roles.CaseManager,
      LocalEnumerations.Roles.SuperUser,
    ]).then((rst) => {
      if (rst.isFailure || !rst.result) {
        setError(locales.errorDetails.permissionError);
        return;
      }
    });

    CaseUtils.getTriageByCaseAsync(
      caseId!,
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!
    ).then((rst) => {
      if (rst.isFailure) {
        setError(locales.errorDetails.loadingFailed);
        return;
      }

      if (
        rst.result?.caseTriage !== undefined &&
        rst.result?.caseTriage !== null
      ) {
        setFormData(rst.result!.caseTriage!);
      }

      setIsAwaitingPrerequisites(
        rst.result?.currentCaseStatus ===
          (LocalEnumerations.CaseStatuses.AwaitingPrerequisites as number)
      );
      setIsLoading(false);
    });
    context?.onEvent(locales.breadcrumbs);
  }, []);

  /**
   * Handles the change event for form controls and updates the form data state.
   * @param result - The change event data containing the field ID and value.
   */
  const handleDataChange = (
    result: FormControlOnChangeData<
      boolean | string | number | number[] | string[] | null
    >
  ) => {
    const consent =
      result.fieldId === 'consentReceived'
        ? result.value
        : formData.consentReceived;
    const payment =
      result.fieldId === 'paymentApproved'
        ? result.value
        : formData.paymentApproved;
    const questions =
      result.fieldId === 'questionsCompleted'
        ? result.value
        : formData.questionsCompleted;

    const disableSave = !consent || !payment || !questions;

    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: result.value,
    }));

    setSaveDisabled(disableSave);
  };

  /**
   * Handles the change event for Date based form controls and updates the form data state.
   * @param result - The change event data containing the field ID and value.
   */
  const handleDateChange = (result: FormControlOnChangeData<Date | null>) => {
    if (result.value === null) {
      return;
    }
    setFormData((prevData) => ({
      ...prevData,
      [result.fieldId]: new Date(result.value!) || null,
    }));
  };

  const saveRecord = () => {
    const validationResults = pageRefs.map((innerRef) =>
      innerRef.current?.triggerValidation()
    );
    const tabValid = validationResults.every((result) => result === true);
    if (!tabValid) {
      setTabState('negative');
      return;
    }

    CaseUtils.saveTriageAsync(
      formData,
      userClaims!.user!,
      instance.getActiveAccount()!.idToken!
    ).then((result) => {
      if (result.isFailure) {
        setError(
          getServerErrors(locales, result.errorCode),
          result.error,
          () => {
            setErrorProps((prevData) => ({
              ...prevData,
              show: false,
            }));
          }
        );
        return;
      }
      navigate('/Cases');
    });
  };

  return isLoading ? (
    <PageLoader alt={locales.common.load} />
  ) : errorProps.show ? (
    <div>
      <CommonModal
        headerText={errorProps.headerText}
        buttonDetails={errorProps.buttonDetails}
        show={errorProps.show}
      >
        {errorProps.children || errorProps.bodyText}
      </CommonModal>
    </div>
  ) : (
    <div className="Main-Form-Layout">
      <PillControl
        pages={[
          {
            name: locales.pageName,
            mode: tabState,
            enabled: true,
            orderNo: 1,
            showAsOrdered: true,
            content: (
              <>
                <FormCheckbox
                  displayMode="Box"
                  fieldId="paymentApproved"
                  id="paymentApproved"
                  label={locales.labels.paymentApproved}
                  value={formData?.paymentApproved ?? false}
                  onChange={handleDataChange}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[0]}
                />
                <FormTextCapture
                  displayMode="Box"
                  fieldId="purchaseOrderNumber"
                  id="purchaseOrderNumber"
                  textInputType="text"
                  maxLength={50}
                  label={locales.labels.purchaseOrderNumber}
                  value={formData?.purchaseOrderNumber ?? ''}
                  onChange={handleDataChange}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[1]}
                />
                <FormDate
                  displayMode="Box"
                  fieldId="paymentApprovedDate"
                  id="paymentApprovedDate"
                  label={locales.labels.paymentApprovedDate}
                  value={getDateOrNull(formData?.paymentApprovedDate)}
                  onChange={handleDateChange}
                  invalidDateText={locales.common.invalidDate}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[2]}
                  datePartLabels={locales.labels.dateParts}
                />
                <FormCheckbox
                  displayMode="Box"
                  fieldId="consentReceived"
                  id="consentReceived"
                  label={locales.labels.consentReceived}
                  value={formData?.consentReceived ?? false}
                  onChange={handleDataChange}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[3]}
                />
                <FormDate
                  displayMode="Box"
                  fieldId="consentReceivedDate"
                  id="consentReceivedDate"
                  label={locales.labels.consentReceivedDate}
                  value={getDateOrNull(formData?.consentReceivedDate)}
                  onChange={handleDateChange}
                  invalidDateText={locales.common.invalidDate}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[4]}
                  datePartLabels={locales.labels.dateParts}
                />
                <FormDate
                  displayMode="Box"
                  fieldId="consentApprovedDate"
                  id="consentApprovedDate"
                  label={locales.labels.consentApprovedDate}
                  value={getDateOrNull(formData?.consentApprovedDate)}
                  onChange={handleDateChange}
                  invalidDateText={locales.common.invalidDate}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[5]}
                  datePartLabels={locales.labels.dateParts}
                />
                <FormCheckbox
                  displayMode="Box"
                  fieldId="questionsCompleted"
                  id="questionsCompleted"
                  label={locales.labels.questionsCompleted}
                  value={formData?.questionsCompleted ?? false}
                  onChange={handleDataChange}
                  readonly={!isAwaitingPrerequisites}
                  ref={pageRefs[6]}
                />
              </>
            ),
          },
        ]}
      />
      {isAwaitingPrerequisites ? (
        <InformationButton
          key="informationSave"
          buttonDetails={{
            itemKey: 'informationSave',
            clickEvent: saveRecord,
            label: locales.labels.saveButton,
            mode: 'positive',
            disabled: saveDisabled,
          }}
        >
          <>{locales.labels.saveActionSummary}</>
        </InformationButton>
      ) : (
        <p>{locales.triageAlreadyCompletedSummary}</p>
      )}
    </div>
  );
};

export default CreateTriagePage;
